import { useGetCurrentMnemoScheme } from '@/hooks/api/use-get-current-mnemo-scheme'
import { useUploadImageMutation } from '@/store/api/images.api'
import { useAddMnemonicItemMutation } from '@/store/api/mnemo.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { InboxOutlined } from '@ant-design/icons'
import type { GetProp, ModalProps, UploadFile, UploadProps } from 'antd'
import { Modal, Upload } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

type TProps = ModalProps & {
  onCancel: () => void
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]
const ImageModal: FC<TProps> = ({ onCancel, ...props }) => {
  const { data: currentMnemonicSchemeData } = useGetCurrentMnemoScheme()
  const [images, setImages] = useState<UploadFile[]>([])
  const [uploadImage] = useUploadImageMutation()
  const [addMnemonicItem] = useAddMnemonicItemMutation()

  const handleModalCancel = () => {
    setImages([])
    onCancel()
  }
  const handleLoadImagesOk = async () => {
    const uploadPromises = images.map((image) => {
      const formData = new FormData()
      formData.append('file', image as FileType)
      return uploadImage(formData).unwrap()
    })
    try {
      const uploadedImages = await Promise.all(uploadPromises)

      const addItemPromises = uploadedImages.map((image) =>
        addMnemonicItem({
          mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
          name: image.name,
          x: 10,
          y: 10,
          staticImageElement: {
            fileId: image.id,
            width: null,
            height: null
          }
        }).unwrap()
      )

      await Promise.all(addItemPromises)

      successNotificationCreate('Изображения успешно загружены')
    } catch (error) {
      errorNotificationCreate(error)
    } finally {
      setImages([])
      onCancel()
    }
  }
  const handleBeforeUpload = (file: UploadFile, fileList: UploadFile[]) => {
    console.log(fileList)
    setImages([...images, ...fileList])
    return false
  }

  const handleRemoveImage = (file: UploadFile) => {
    const index = images.indexOf(file)
    const newImages = images.slice()
    newImages.splice(index, 1)
    setImages(newImages)
  }

  return (
    <Modal
      centered={true}
      {...props}
      title='Загрузить изображения'
      okText={'Загрузить'}
      cancelText={'Закрыть'}
      maskClosable={false}
      onOk={handleLoadImagesOk}
      onCancel={handleModalCancel}
    >
      <Upload.Dragger
        name='image'
        listType='text'
        beforeUpload={handleBeforeUpload}
        multiple={true}
        onRemove={handleRemoveImage}
        fileList={images}
        showUploadList={true}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Нажмите или перенести изображение в зону для загрузки</p>
      </Upload.Dragger>
    </Modal>
  )
}

export default ImageModal
