import MachineIcon from '@/components/icons/equipments/machine-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import { ConfigurationRoute, MainRoute } from '@/constants/navigation/routes.constant'
import { EConnectionContent } from '@/enums/connection/connection-content.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachinesQuery } from '@/store/api/machines.api'
import { useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { IMachineInfo } from '@/types/machine.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import { findNode } from '@/utils/tree/find-node'
import { updateTreeData } from '@/utils/tree/update-tree-data'
import { Tree } from 'antd'
import type { EventDataNode } from 'antd/es/tree'
import type { FC, Key } from 'react'
import { useEffect, useState } from 'react'
import type { ParamKeyValuePair } from 'react-router-dom'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import styles from './connection-equipments-tree.module.css'

const mapPointsToTree = (points: ISelectedMeasuringPoint[]): ITreeNode<EConnectionContent>[] =>
  points.map((point) => ({
    key: point.pointId,
    title: point.name,
    isLeaf: true,
    content: EConnectionContent.Points,
    icon: <PointIcon />
  }))

const renderMachineTree = (machines: IMachineInfo[]): ITreeNode<EConnectionContent>[] =>
  machines.map((machine) => ({
    key: machine.machineId,
    title: machine.name,
    className: styles['tree-item'],
    content: EConnectionContent.Machines,
    icon: <MachineIcon />
  }))

const PATH = `/${MainRoute.Configuration}/${ConfigurationRoute.Connections}`
const EXPENDED_SEARCH_KEY = 'exp_equip'

const ConnectionEquipmentsTree: FC = () => {
  const { pointId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: machinesResponse } = useGetMachinesQuery()
  const [triggerGetPoints, { currentData: dataPoints, isSuccess: isSuccessDataPoints }] =
    useLazyGetMeasuringPointsQuery()
  const { setSelectedMachineId, setSelectedPointId } = useActions()
  const { selectedMachineId, selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const machines = machinesResponse?.content
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const [treeData, setTreeData] = useState<ITreeNode<EConnectionContent>[]>([])

  useEffect(() => {
    if (pointId) {
      const foundNodePath = findNode<EConnectionContent>(treeData, pointId)
      if (foundNodePath?.path) {
        setSelectedMachineId(foundNodePath.path[0].toString())
      }

      setSelectedPointId(pointId)
      setSelectedKeys([pointId])
    }

    setExpandedKeys(searchParams.getAll(EXPENDED_SEARCH_KEY))
  }, [pointId, searchParams, setSelectedMachineId, setSelectedPointId, treeData])

  useEffect(() => {
    if (searchParams.getAll(EXPENDED_SEARCH_KEY).length === 0 && selectedMachineId) {
      const search: ParamKeyValuePair = [EXPENDED_SEARCH_KEY, selectedMachineId]
      setSearchParams([search])
    }

    if (!pointId && selectedPointId) {
      const path = `${PATH}/${selectedPointId}${location.search}`
      navigate(path)
    }
  }, [location.search, navigate, pointId, searchParams, selectedMachineId, selectedPointId, setSearchParams])

  useEffect(() => {
    if (machines) {
      setTreeData(renderMachineTree(machines))
    }
  }, [machines])

  const handleDeviceSelect = (keys: Key[], info: { node: EventDataNode<ITreeNode<EConnectionContent>> }) => {
    if (info.node?.content === EConnectionContent.Machines) {
      return
    }
    const id = keys[0] || ''
    const path = `${PATH}/${id}${location.search}`
    navigate(path)
  }

  const handleExpand = (
    keys: Key[],
    info: { expanded: boolean; node: EventDataNode<ITreeNode<EConnectionContent>> }
  ) => {
    const params: ParamKeyValuePair[] = keys.map((item) => [EXPENDED_SEARCH_KEY, item.toString()])
    setSearchParams(params)
  }

  const handleLoadData = async (treeNode: EventDataNode<ITreeNode<EConnectionContent>>) => {
    const { key } = treeNode
    const id = key as string
    const { content: points } = await triggerGetPoints(id).unwrap()
    const pointsTree = mapPointsToTree(points)
    const updatedTreeData = updateTreeData(treeData, id, pointsTree)
    setTreeData(updatedTreeData)
  }

  return (
    <Tree
      className={styles['tree']}
      showIcon={true}
      showLine={true}
      treeData={treeData}
      onSelect={handleDeviceSelect}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      selectedKeys={selectedKeys}
      loadData={handleLoadData}
    />
  )
}

export default ConnectionEquipmentsTree
