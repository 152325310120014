import {
  DcmOutName,
  DcmParamName
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form.constant'
import type {
  IFormDcm,
  IOutDataDcm,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form.type'
import { TypeSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import {
  calculateValue,
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import type { IKinematicElement, IOut } from '@/types/kinematic.type'
import { isNumber } from '@/utils/common'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockDCM, outs } = kinematicElement

  const result: {
    fields: IFormDcm
    outs: IOuts | null
  } = {
    fields: {
      [DcmParamName.Description]: description,
      [DcmParamName.FreqCoefForIn]: ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [DcmParamName.TypeSource]: TypeSource.Missing,
      [DcmParamName.Fu]: null,
      [DcmParamName.N2p]: null,
      [DcmParamName.Nr]: null,
      [DcmParamName.Nc]: null
    },
    outs: null
  }

  if (ins) {
    const typeSource = ins[0].measurementSourseId ? TypeSource.Mp : TypeSource.Manual

    const insAdapted = {
      [DcmParamName.TypeSource]: typeSource,
      [DcmParamName.FreqOutSource]: ins[0].freqInValue,
      [DcmParamName.FreqCoefForIn]: ins[0].freqCoefForIn
    }

    Object.assign(result.fields, insAdapted)
  }

  if (kinematicBlockDCM) {
    const { fu, n2p, nr, nc } = kinematicBlockDCM

    Object.assign(result.fields, {
      [DcmParamName.Fu]: fu,
      [DcmParamName.N2p]: n2p,
      [DcmParamName.Nr]: nr,
      [DcmParamName.Nc]: nc
    })
  }

  if (outs) {
    const outsData: Record<DcmOutName, IOut> = Object.values(DcmOutName).reduce((acc, key, index) => {
      acc[key] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<DcmOutName, IOut>)

    result.outs = outsData
  }

  return result
}

export const getCalculatedResults = (fieldsValue: IFormDcm, kinematicBlockName?: string) => {
  const { fu, nc, nr, freqOutSource, freqCoefForIn } = fieldsValue
  const freqInValue = isNumber(freqOutSource) && isNumber(freqCoefForIn) ? freqOutSource * freqCoefForIn : null

  const resultOuts: IOuts = {
    [DcmOutName.Fu]: {
      freqOutName: `${kinematicBlockName}_Fu`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: fu
    },
    [DcmOutName.Frsd]: {
      freqOutName: `${kinematicBlockName}_Frsd`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: freqCoefForIn,
      freqOutValue: freqInValue
    },
    [DcmOutName.Fe]: {
      freqOutName: `${kinematicBlockName}_Fe`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(fu, 2)
    },
    [DcmOutName.Fnl]: {
      freqOutName: `${kinematicBlockName}_Fnl`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(fu, 6)
    },
    [DcmOutName.Fdcmz]: {
      freqOutName: `${kinematicBlockName}_Fdcmz`,
      freqOutCoefKb: nr,
      freqOutCoefMachine: nr,
      freqOutValue: calculateValue(freqInValue, nr)
    },
    [DcmOutName.Fdcmc]: {
      freqOutName: `${kinematicBlockName}_Fdcmc`,
      freqOutCoefKb: nc,
      freqOutCoefMachine: nc,
      freqOutValue: calculateValue(freqInValue, nc)
    }
  }

  return resultOuts
}

export const formatDataForTable = (outs: IOuts): IOutDataDcm[] => {
  const { fu, fdcmc, fdcmz, fe, fnl, frsd } = outs
  const data: Omit<IOutDataDcm, 'key'>[] = [
    {
      label: 'Частота питающей сети',
      name: handleName(fu.freqOutName),
      value: handleValue(fu.freqOutValue)
    },
    {
      label: 'Частота вращения',
      name: handleName(frsd.freqOutName),
      value: handleValue(frsd.freqOutValue)
    },
    {
      label: 'Частота ЭМ взаимодействия (магнитострикционная, ЭМС)',
      name: handleName(fe.freqOutName),
      value: handleValue(fe.freqOutValue)
    },
    {
      label: 'Частота искажения питания',
      name: handleName(fnl.freqOutName),
      value: handleValue(fnl.freqOutValue)
    },
    {
      label: 'Зубцовая частота DCM',
      name: handleName(fdcmz.freqOutName),
      value: handleValue(fdcmz.freqOutValue)
    },
    {
      label: 'Коллекторная частота DCM',
      name: handleName(fdcmc.freqOutName),
      value: handleValue(fdcmc.freqOutValue)
    }
  ]

  return data.map<IOutDataDcm>((item, key) => ({ ...item, key }))
}

export const parseDataForChangeDcm = (
  kinematicElement: IKinematicElement,
  fieldValues: IFormDcm,
  outsData: IOuts
): IKinematicElement => {
  const { description, freqCoefForIn, n2p, fu, nr, nc } = fieldValues
  const data: IKinematicElement = {
    ...kinematicElement,
    description: description,
    kinematicBlockDCM: {
      n2p: n2p,
      fu: fu,
      nr: nr,
      nc: nc
    },
    ins: [
      {
        freqInName: null,
        freqOutCoefIndexSourse: null,
        measurementSourseId: null,
        kinematicBlockSourseId: null,
        freqOutCoefMachineSourse: null,
        freqInValue: fieldValues.freqOutSource || null,
        freqOutNameSourse: 'Fin1',
        freqInCoefMachine: freqCoefForIn,
        freqCoefForIn: freqCoefForIn
      }
    ],
    outs: Object.values(outsData)
  }

  return data
}
