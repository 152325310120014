import type { IToolProps } from '@/components/mnemonic/ui/tool.interface'
import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import { useGetMnemoStats } from '@/hooks/api/use-get-mnemo-stats'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type Konva from 'konva'
import type { FC } from 'react'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { Circle, Group, Line, Rect, Text, Transformer } from 'react-konva'

const MeasurementWithThresholdIndicatorElement: FC<IToolProps> = ({
  element,
  updateMnemonicElement,
  draggable,
  parentSize,
  setContextMenuOptions
}) => {
  const groupRef = useRef<Konva.Group>(null)
  const transformerRef = useRef<Konva.Transformer>(null)
  const pointRef = useRef<Konva.Circle>(null)
  const lineRef = useRef<Konva.Line>(null)
  const { selectedMnemoItemId } = useTypedSelector((state) => state.mnemonicReducer)
  const { setSelectedMnemoItemId } = useActions()
  const isSelected = selectedMnemoItemId === element?.mnemoElementId

  const { setSelectedMeasurementId } = useActions()

  const { data: dataMnemoStats } = useGetMnemoStats()

  const thresholdState = dataMnemoStats?.mnemoElementState?.find(
    (indicator) => indicator.mnemoElementId === element?.mnemoElementId
  )?.thresholdStateDto

  const lastRift = Number(thresholdState?.rifts[0]?.riftValue)
  const preLastRift = Number(thresholdState?.rifts[1 as number]?.riftValue)

  const firstRift = Number(thresholdState?.rifts[thresholdState?.rifts.length - 1]?.riftValue)

  const secondRift = Number(thresholdState?.rifts[thresholdState?.rifts.length - 2]?.riftValue)

  const validatePercentOfIndicator = (value: number) => {
    if (value < 1) {
      return 0
    } else if (value > 99) {
      return 100
    }

    return value
  }

  const calcPercentCell = useCallback((value: number, allRange: number) => {
    const percent = (value / allRange) * 100

    return percent
  }, [])

  const calcLastValue = (value: number | undefined) => {
    if (
      !element?.measurementWithThresholdIndicatorElement?.height ||
      !lastRift ||
      !firstRift ||
      !preLastRift ||
      !secondRift
    )
      return

    const allRange =
      Math.abs(lastRift - firstRift) + Math.abs(lastRift - preLastRift) / 2 + Math.abs(secondRift - firstRift) / 2

    if (value && value < firstRift - (secondRift - firstRift)) {
      value = firstRift - (secondRift - firstRift)
    }

    const percent =
      value !== undefined && value !== null
        ? 100 - calcPercentCell(value - (secondRift - firstRift) / 2 + (secondRift - firstRift * 2), allRange)
        : 50

    const position = Math.abs(
      (element.measurementWithThresholdIndicatorElement.height * validatePercentOfIndicator(percent)) / 100
    )

    return position
  }

  const calcHeightRect = (i: number, variant: 'position' | 'height') => {
    if (!element?.measurementWithThresholdIndicatorElement) return

    if (!thresholdState) return

    const allRange =
      Math.abs(firstRift - lastRift) + Math.abs(lastRift - preLastRift) / 2 + Math.abs(secondRift - firstRift) / 2

    let value

    if (variant === 'position') {
      if (i === thresholdState?.zones.length - 1) {
        value = 0.00001 // последняя позиция
      } else {
        value = Math.abs(
          Number(thresholdState?.rifts[i]?.riftValue) -
            Math.abs(secondRift - firstRift) / 2 +
            (secondRift - firstRift * 2)
        ) // остальные позиции
      }
    } else if (variant === 'height') {
      if (i === 0) {
        value = Math.abs(lastRift - preLastRift) / 2 // последняя высота
      } else if (i === thresholdState?.zones.length - 1) {
        value = Math.abs(secondRift - firstRift) / 2 // первая высота
      } else {
        value = Math.abs(Number(thresholdState?.rifts[i]?.riftValue) - Number(thresholdState?.rifts[i - 1]?.riftValue)) // остальные высоты
      }
    }

    if (!value) return

    const percentOfValue = (variant === 'position' ? 100 : 0) - calcPercentCell(value, allRange)
    const height = (Math.abs(element.measurementWithThresholdIndicatorElement.height) * percentOfValue) / 100

    return isNaN(height) ? undefined : height
  }

  useEffect(() => {
    if (!transformerRef?.current || !groupRef?.current) return

    transformerRef.current.nodes([groupRef?.current])
    transformerRef.current.getLayer()?.batchDraw()
  }, [])

  useLayoutEffect(() => {
    //для того чтобы не прыгало при ресайзе
    if (!groupRef?.current) return

    groupRef.current.scaleX(1)
    groupRef.current.scaleY(1)
  }, [element])

  if (!element || !parentSize) return null

  return (
    <>
      {element.pointerPosition ? (
        <Line
          ref={lineRef}
          points={[
            pointRef.current?.attrs.x || element.pointerPosition?.x || 0,
            pointRef.current?.attrs.y || element.pointerPosition?.y || 0,
            groupRef.current?.attrs.x + 10 || element.x + 10 || 0,
            groupRef.current?.attrs.y + 10 || element.y + 10 || 0
          ]}
          stroke={'#000'}
          strokeWidth={1}
        />
      ) : null}

      <Group
        onClick={
          draggable
            ? () => {
                if (element) {
                  setSelectedMnemoItemId(element.mnemoElementId)
                }
              }
            : undefined
        }
        onTransformEnd={() => {
          const node = groupRef.current

          if (!node || !updateMnemonicElement || !element?.measurementWithThresholdIndicatorElement) return

          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          updateMnemonicElement({
            ...element,
            x: node.x(),
            y: node.y(),
            measurementWithThresholdIndicatorElement: {
              ...element.measurementWithThresholdIndicatorElement,
              width: node.width() * scaleX,
              height: node.height() * scaleY
            }
          })
        }}
        onContextMenu={(e) => {
          e.evt.preventDefault()

          const pointerPosition = e.target.getStage()?.getPointerPosition()

          if (!pointerPosition || !setContextMenuOptions) return

          setContextMenuOptions({
            isOpen: true,
            position: {
              x: pointerPosition.x,
              y: pointerPosition.y
            }
          })
        }}
        onMouseEnter={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grab'
        }}
        onMouseDown={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grabbing'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'default'
        }}
        ref={groupRef}
        x={element.x}
        y={element.y}
        width={element.measurementWithThresholdIndicatorElement?.width}
        height={element.measurementWithThresholdIndicatorElement?.height}
        draggable={draggable}
        onDragEnd={(e) => {
          if (!updateMnemonicElement || !element.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0) return

          updateMnemonicElement({
            ...element,
            x: e.target.attrs.x,
            y: e.target.attrs.y
          })
        }}
      >
        <Rect
          width={element.measurementWithThresholdIndicatorElement?.width}
          height={(element.measurementWithThresholdIndicatorElement?.height || 0) * 0.1}
          strokeScaleEnabled={false}
          stroke={'#000'}
          strokeWidth={1}
          x={0}
          y={-((element.measurementWithThresholdIndicatorElement?.height || 0) * 0.11) - 1}
          fill={
            thresholdState?.dangerLevel === EThresholdLevel.LEVEL_1
              ? 'rgb(132, 208, 149)'
              : thresholdState?.dangerLevel === EThresholdLevel.LEVEL_2
              ? 'rgb(212, 234, 136)'
              : thresholdState?.dangerLevel === EThresholdLevel.LEVEL_3
              ? 'rgb(253, 233, 167)'
              : thresholdState?.dangerLevel === EThresholdLevel.LEVEL_4
              ? '#ff9774'
              : 'rgb(205, 209, 239)'
          }
        />
        <Text
          fontSize={(element.measurementWithThresholdIndicatorElement?.width || 0) * 0.25}
          x={1}
          y={
            (-((element.measurementWithThresholdIndicatorElement?.height || 0) * 0.11) -
              1 -
              (element.measurementWithThresholdIndicatorElement?.width || 0) * 0.25) /
            2
          }
          width={element.measurementWithThresholdIndicatorElement?.width}
          text={thresholdState?.lastValue?.toPrecision(4) || 'Знач.'}
          align='center'
          fill={
            thresholdState?.dangerLevel === EThresholdLevel.LEVEL_4 ||
            thresholdState?.dangerLevel === EThresholdLevel.NONE
              ? 'rgb(253, 233, 167)'
              : ''
          }
        />

        {thresholdState?.zones?.length ? (
          thresholdState.zones.map((zone, i) => (
            <React.Fragment key={i}>
              {thresholdState?.rifts[i - 1]?.riftValue ? (
                <Text
                  fontSize={(element.measurementWithThresholdIndicatorElement?.width || 0) * 0.25}
                  x={
                    (element.measurementWithThresholdIndicatorElement?.width || 0) +
                    (element.measurementWithThresholdIndicatorElement?.width || 0) * 0.05
                  }
                  y={
                    (calcHeightRect(i - 1, 'position') ||
                      (element.measurementWithThresholdIndicatorElement?.width || 0) * 0.25) -
                    (element.measurementWithThresholdIndicatorElement?.width || 0) * 0.25
                  }
                  text={String(thresholdState?.rifts[i - 1]?.riftValue)}
                />
              ) : null}
              <Rect
                strokeScaleEnabled={false}
                width={element.measurementWithThresholdIndicatorElement?.width}
                y={calcHeightRect(i, 'position')}
                height={calcHeightRect(i, 'height')}
                stroke={isSelected ? 'red' : undefined}
                fill={
                  zone.dangerousType === EThresholdLevel.LEVEL_1
                    ? 'rgb(132, 208, 149)'
                    : zone.dangerousType === EThresholdLevel.LEVEL_2
                    ? 'rgb(212, 234, 136)'
                    : zone.dangerousType === EThresholdLevel.LEVEL_3
                    ? 'rgb(253, 233, 167)'
                    : zone.dangerousType === EThresholdLevel.LEVEL_4
                    ? '#ff9774'
                    : 'rgb(205, 209, 239)'
                }
              />
            </React.Fragment>
          ))
        ) : (
          <Rect
            strokeScaleEnabled={false}
            width={element.measurementWithThresholdIndicatorElement?.width}
            height={element.measurementWithThresholdIndicatorElement?.height}
            stroke={isSelected ? 'red' : undefined}
            fill={'rgb(205, 209, 239)'}
          />
        )}

        <Line
          strokeScaleEnabled={false}
          points={[0, 0, element.measurementWithThresholdIndicatorElement?.width || 0, 0]}
          y={calcLastValue(thresholdState?.lastValue)}
          stroke={'#000'}
        />
      </Group>

      <Transformer
        flipEnabled={false}
        resizeEnabled={draggable}
        borderEnabled={draggable}
        ref={transformerRef}
        rotateEnabled={false}
        boundBoxFunc={(oldBox, newBox) => {
          const node = groupRef.current
          const line = lineRef.current

          if (!node) return oldBox

          /* const isOut =
            circle.x() < 0 ||
            circle.y() < 0 ||
            circle.x() + circle.width() * circle.getAbsoluteScale().x >=
              (parentSize?.width || 1) ||
            circle.y() + circle.height() * circle.getAbsoluteScale().y >=
              (parentSize?.height || 1);

          if (isOut)
            return {
              ...oldBox,
              height: oldBox.height - 1,
              width: oldBox.width - 1,
            }; */

          if (line) {
            line.attrs.points = [line.attrs.points[0], line.attrs.points[1], node.x(), node.y()]
          }

          return newBox
        }}
        onDragMove={() => {
          const node = groupRef.current
          const newPosition = { x: node?.attrs.x, y: node?.attrs.y }

          if (!newPosition?.x || !newPosition?.y || !node) return

          if (newPosition.x < 1) {
            newPosition.x = 1
          }

          if (newPosition.x > Number(parentSize?.width) - node.width()) {
            newPosition.x = Number(parentSize?.width) - node.width()
          }

          if (newPosition.y < 1) {
            newPosition.y = 1
          }

          if (newPosition.y > Number(parentSize?.height) - node.height()) {
            newPosition.y = Number(parentSize?.height) - node.height()
          }

          node?.setPosition(newPosition)

          if (!lineRef.current) return

          lineRef.current.attrs.points = [
            lineRef.current.attrs.points[0],
            lineRef.current.attrs.points[1],
            newPosition.x,
            newPosition.y
          ]
        }}
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      />

      {element.pointerPosition ? (
        <Circle
          ref={pointRef}
          draggable={draggable}
          fill={'#000'}
          width={10}
          height={10}
          onDragEnd={(e) => {
            if (!updateMnemonicElement || !element.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0) return

            updateMnemonicElement({
              ...element,
              pointerPosition: {
                x: e.target.attrs.x,
                y: e.target.attrs.y
              }
            })
          }}
          onDragMove={(e) => {
            if (!lineRef.current) return

            lineRef.current.attrs.points = [
              e.target.attrs.x,
              e.target.attrs.y,
              lineRef.current.attrs.points[2],
              lineRef.current.attrs.points[3]
            ]
          }}
          x={element.pointerPosition?.x}
          y={element.pointerPosition?.y}
        />
      ) : null}
    </>
  )
}

export default MeasurementWithThresholdIndicatorElement
