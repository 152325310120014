import type { IMeasurementForm } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import TextLabel from '@/components/UI/form-controls/text-label/text-label'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import { MeasurementLabel, MeasurementName } from '@/constants/measurement/measurements-form.constant'
import { temperatureTypeOptions } from '@/constants/measurement/measurements.constant'
import { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import { validateMinValue } from '@/utils/validation-rules'
import type { FormInstance } from 'antd'
import { InputNumber, Select } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

interface IProps {
  isReaderMode?: boolean
  isWriterMode?: boolean
  manualTemperatureType?: string
  manualCoef?: string
  measurementForm: FormInstance<IMeasurementForm>
}

const TemperatureForm: FC<IProps> = ({
  isReaderMode,
  isWriterMode,
  manualTemperatureType,
  manualCoef,
  measurementForm
}) => {
  const [selectedTemperature, setSelectedTemperature] = useState(ETemperatureType.OBJECT)
  const isTemperatureSensor = selectedTemperature === ETemperatureType.SENSOR

  const handleTemperatureTypeChange = (value: ETemperatureType) => {
    setSelectedTemperature(value)
    if (value === ETemperatureType.SENSOR) {
      measurementForm.setFieldValue(MeasurementName.Coef, undefined)
    }
  }

  return (
    <>
      <FormItemApp
        name={MeasurementName.TemperatureType}
        label={MeasurementLabel.TemperatureType}
        initialValue={ETemperatureType.OBJECT}
        rules={[{ required: isWriterMode, message: 'Поле обязательно' }]}
      >
        {isWriterMode && <Select options={temperatureTypeOptions} onChange={handleTemperatureTypeChange} />}
        {isReaderMode && <TextLabel manualValue={manualTemperatureType} />}
      </FormItemApp>
      {manualCoef && (
        <FormItemApp
          name={MeasurementName.Coef}
          initialValue={1}
          label={MeasurementLabel.Coef}
          rules={[
            { required: isWriterMode && !isTemperatureSensor, message: 'Поле обязательно' },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          {isWriterMode && (
            <InputNumber min={0} max={1} step={0.05} decimalSeparator={','} disabled={isTemperatureSensor} />
          )}
          {isReaderMode && <TextLabel manualValue={manualCoef} />}
        </FormItemApp>
      )}
      <FormItemApp
        name={MeasurementName.NumberOfAverages}
        label={MeasurementLabel.NumberOfAverages}
        rules={[
          { required: isWriterMode, message: 'Поле обязательно' },
          {
            validator: validateMinValue(0)
          }
        ]}
        initialValue={100}
      >
        {isWriterMode && <InputNumber min={0} max={65535} step={1} />}
        {isReaderMode && <TextLabel />}
      </FormItemApp>
    </>
  )
}

export default TemperatureForm
