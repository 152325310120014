import { Vp3701ChannelType, Vp3701ChannelTypeMap } from '@/constants/point/point.constant'

export const MeasurementLabel = {
  Name: 'Наименование',
  TypeControl: 'Вид контроля',
  DeviceType: 'Тип устройства',
  MeasurementType: 'Вид измерения',
  isActivated: 'Активно',
  UnitType: 'Единица измерения',
  SamplingRate: 'Частота дискретизации, Гц',
  DurationInSec: 'Длительность сигнала, сек',
  IirFilterEnabled: 'Антиалайзинговый фильтр',
  VisualFreqLimit: 'Граничная частота',
  SpecterLineCount: 'Количество линий',
  Detector: 'Детектор',
  CommonLevelType: 'Полоса, Гц',
  Coef: 'Коэффициент излучения',
  NumberOfAverages: 'Количество усреднений',
  TemperatureType: 'Источник'
} as const

export const MeasurementName = {
  MeasurementName: 'measurementName',
  TypeControl: 'typeControl',
  TargetType: 'targetType',
  MeasurementType: 'measurementType',
  isActivated: 'isActivated',
  UnitType: 'unitType',
  SamplingRate: 'samplingRate',
  DurationInSec: 'durationInSec',
  IirFilterEnabled: 'iirFilterEnabled',
  VisualFreqLimit: 'visualFreqLimit',
  SpecterLineCount: 'specterLineCount',
  Detector: 'detector',
  CommonLevelType: 'commonLevelType',
  Coef: 'coef',
  NumberOfAverages: 'numberOfAverages',
  TemperatureType: 'temperatureType'
} as const

export const temperatures = [
  {
    label: Vp3701ChannelTypeMap[Vp3701ChannelType.InternalSensorTemperature],
    value: Vp3701ChannelType.InternalSensorTemperature
  },
  {
    label: Vp3701ChannelTypeMap[Vp3701ChannelType.ObjectTemperature],
    value: Vp3701ChannelType.ObjectTemperature
  }
]
