import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IChartScalar, IChartZone, IMeasurementScalarResult } from '@/types/chart/charts.type'
import type { TBaseConverter } from '@/types/measurement/converters.type'
import type { IThreshold } from '@/types/threshold/threshold.type'
import { convertBaseAcceleration, convertTemperature } from '@/utils/measurement/units-converters'
import { max, min } from 'lodash'

export const getChartData = (
  sourceData: IMeasurementScalarResult[],
  sourceUnit: EUnitType,
  targetUnit: EUnitType,
  isBaseUnit: boolean
) =>
  sourceData.map<IChartScalar>((scalarItem, scalarIndex) => {
    const convertedValue = isBaseUnit
      ? convertBaseAcceleration(sourceUnit, targetUnit, scalarItem.value)
      : convertTemperature(sourceUnit, targetUnit, scalarItem.value)

    return {
      value: convertedValue || scalarItem.value,
      index: scalarIndex + 1,
      time: scalarItem.time
    }
  })

export const getChartThresholds = (
  threshold: IThreshold,
  sourceUnit: EUnitType,
  targetUnit: EUnitType,
  rangeValues: number[],
  converter: TBaseConverter
): IChartZone[] =>
  threshold.zones.map<IChartZone>((zone, index, list) => {
    const [minValue, maxValue] = rangeValues
    let upper: number | undefined = threshold.rifts[index - 1]?.riftValue
    let lower: number | undefined = threshold.rifts[index]?.riftValue
    const hysteresis = threshold.rifts[index]?.hysteresisCoef
      ? lower - threshold.rifts[index]?.hysteresisCoef
      : undefined

    if (upper !== undefined && upper !== maxValue) {
      upper = converter(sourceUnit, targetUnit, upper) || upper
    }

    if (lower !== undefined && lower !== minValue) {
      lower = converter(sourceUnit, targetUnit, lower) || lower
    }

    if (upper !== undefined && upper > maxValue) {
      upper = maxValue
    }

    if (lower !== undefined && lower < minValue) {
      upper = minValue
    }

    return {
      lower: lower,
      upper: upper,
      thresholdLevel: zone.dangerousType,
      hysteresis: hysteresis
    }
  })

export const getRangeValues = (
  chartData: IChartScalar[],
  rangeIndexes: number[],
  isVerticaSliderDisable: boolean
): number[] => {
  let values = chartData.map((element) => element.value)
  if (isVerticaSliderDisable) {
    values = values.slice(rangeIndexes[0] - 1, rangeIndexes[1])
  }

  const minV = min(values) as number
  const maxV = max(values) as number
  return [minV, maxV]
}
