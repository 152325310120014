import type { ThemeConfig } from 'antd'

export const DesignTokensAntd: ThemeConfig = {
  token: {
    colorPrimary: '#000',
    colorPrimaryBorder: '#BBBBBBFF',
    colorBorder: '#BBBBBBFF',
    borderRadiusSM: 0,
    fontFamily: 'Inter, Arial, Helvetica, sans-serif',
    borderRadiusLG: 0,
    borderRadius: 0,
    padding: 4
  },
  components: {
    Button: {
      // defaultHoverBg: 'transparent'
    }
  }
}
