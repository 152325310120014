import { getUnitMeasurement } from '@/app/machine-condition/components/results/components/charts/charts.service'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import useGetMeasurement from '@/hooks/api/use-get-measurement.hook'
import useGetMeasuringPoint from '@/hooks/api/use-get-point.hook'
import { getUnitTypeOptions, getUnitVibroBaseOptions } from '@/utils/measurement/get-unit-type-options'
import { Flex, Select } from 'antd'
import type { FC } from 'react'
import { useEffect } from 'react'

import styles from './chart-unit.module.css'

type TProps = {
  onSetUnitChange: (unit: EUnitType) => void
  unit: EUnitType
  isBaseUnits?: boolean
}

const ChartUnits: FC<TProps> = ({ onSetUnitChange, unit, isBaseUnits }) => {
  const { data: measuringPointResponse } = useGetMeasuringPoint()
  const { data: measurement } = useGetMeasurement()
  const unitOptions = isBaseUnits
    ? getUnitVibroBaseOptions()
    : getUnitTypeOptions(measuringPointResponse?.physicalQuantityType)

  useEffect(() => {
    if (measurement) {
      const unitMeasurement = getUnitMeasurement(measurement)
      onSetUnitChange(unitMeasurement)
    }
  }, [measurement, onSetUnitChange])

  if (!measurement) {
    return null
  }

  const handleSetUnitChange = (value: string) => {
    const unitValue = value as EUnitType
    onSetUnitChange(unitValue)
  }

  return (
    <Flex align='center' gap={5}>
      <p style={{ margin: 0, fontSize: '14px' }}>Ед. изм.</p>
      <Select value={unit} options={unitOptions} onChange={handleSetUnitChange} className={styles['select']} />
    </Flex>
  )
}

export default ChartUnits
