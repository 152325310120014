import {
  MAX_COUNT_LINES_AMPLITUDE,
  MAX_COUNT_LINES_FREQUENCIES
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.constant'
import { generateTicks } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.service'
import ChartSpectrumPanel from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/chart-spectrum-panel/chart-spectrum-panel'
import LegendSection from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/legend-section/legend-section'
import {
  getAmplitudeRange,
  getSpectrumChartData
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-cascade/spectrum-cascade.service'
import TooltipSpectrum from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/tooltip-spectrum/tooltip-spectrum'
import {
  calculateAmFm,
  calculateDeltaFrequency,
  calculateExpressSectionsData,
  calculateSKZ
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/helpers/calculates.helper'
import {
  getChartDataForExpressBars,
  getChartDataForExpressBarsModulates,
  renderExpressBarsChart
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/helpers/render-express-bars-chart.helper'
import { renderExpressMarkersChart } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/helpers/render-express-markers-chart.helper'
import { renderExpressSectionsChart } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/helpers/render-express-sections-chart.helper'
import useBarsKeyHandlers from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-bars-key-handlers'
import useExpressBars from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-bars'
import useExpressMarkers from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-markers'
import useExpressSections from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-sections'
import useMarkersEffects from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-markers-effects'
import useMarkersKeyHandler from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-markers-key-handler'
import ChartsTools from '@/app/machine-condition/components/results/components/charts/components/chart-tools/charts-tools'
import ChartUnits from '@/app/machine-condition/components/results/components/charts/components/chart-units/chart-units'
import ChartWrapper from '@/app/machine-condition/components/results/components/charts/components/chart-wrapper/chart-wrapper'
import SliderApp from '@/app/machine-condition/components/results/components/charts/components/slider-app/slider-app'
import { EAmplitudeMode, EFrequencyMode } from '@/enums/charts/chart-value-mode.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IChartSpectrum, IValuesMode } from '@/types/chart/chart-spectrun.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import { chartFormatValue } from '@/utils/chart/chart-format-value'
import { getStepSlider } from '@/utils/chart/get-step-slider'
import { Checkbox, Flex } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { FC, LegacyRef } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

type TProps = {
  onChartClick: () => void
  resultId: string
  title: string
  chartButtonRef: LegacyRef<HTMLButtonElement>
  sourceData: number[]
  selectedMeasurement: IMeasurement
}

const initialValuesMode: IValuesMode = {
  amplitudeMode: EAmplitudeMode.DB,
  frequencyMode: EFrequencyMode.Liner
}

const SpectrumCascade: FC<TProps> = ({
  onChartClick,
  resultId,
  chartButtonRef,
  title,
  sourceData,
  selectedMeasurement
}) => {
  const [valuesMode, setValuesMode] = useState<IValuesMode>(initialValuesMode)
  const [amplitudeSliderDisabled, setAmplitudeSliderDisabled] = useState(true)
  const [sliderAmplitude, setSliderAmplitude] = useState<number[]>([0, 0])
  const [sliderIndexes, setSliderIndexes] = useState<number[]>([0, 0])
  const [isModulateVisible, setIsModulateVisible] = useState(true)
  const { addExpressSectionResult, setResultsBar } = useActions()
  const [targetUnit, setTargetUnit] = useState<EUnitType>(EUnitType.G)
  const {
    sections,
    isSectionExpressBarOpen,
    isMarkersExpressBarOpen,
    isBarsExpressBarOpen,
    barOptions: expressBarOptions
  } = useTypedSelector(
    (state) => ({
      ...state.expressSectionReducer,
      ...state.expressMarkersReducer,
      ...state.expressBarsReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )
  const isFrequencyModeLog = valuesMode.frequencyMode === EFrequencyMode.LOG
  const isAmplitudeModeLog = valuesMode.amplitudeMode === EAmplitudeMode.LOG
  const isAmplitudeModeDb = valuesMode.amplitudeMode === EAmplitudeMode.DB
  const isDbAmplitude = valuesMode.amplitudeMode === EAmplitudeMode.DB

  const deltaFrequency = calculateDeltaFrequency(selectedMeasurement)

  const chartData = useMemo<IChartSpectrum[]>(
    () => getSpectrumChartData(sourceData, deltaFrequency, valuesMode, selectedMeasurement.unitType, targetUnit),
    [deltaFrequency, selectedMeasurement.unitType, sourceData, targetUnit, valuesMode]
  )

  const rangeAmplitude = useMemo<number[] | undefined>(() => {
    if (!chartData) {
      return undefined
    }

    return getAmplitudeRange(chartData, sliderIndexes, amplitudeSliderDisabled)
  }, [amplitudeSliderDisabled, chartData, sliderIndexes])

  const rangeIndexes = useMemo(() => (chartData ? [0, chartData.length - 1] : [0, 0]), [chartData])

  const rangeFrequency = useMemo<number[] | undefined>(() => {
    if (!chartData) {
      return undefined
    }

    const minIndex = sliderIndexes[0]
    const maxIndex = sliderIndexes[1]
    return [chartData[minIndex]?.frequency, chartData[maxIndex]?.frequency]
  }, [chartData, sliderIndexes])

  useEffect(() => {
    if (chartData) {
      setSliderIndexes(rangeIndexes)
    }
  }, [chartData, rangeIndexes])

  useEffect(() => {
    if (rangeAmplitude && amplitudeSliderDisabled) {
      setSliderAmplitude(rangeAmplitude)
    }
  }, [amplitudeSliderDisabled, rangeAmplitude])

  const ticksFrequencies = useMemo(
    () => generateTicks(rangeFrequency, valuesMode.frequencyMode, MAX_COUNT_LINES_FREQUENCIES),
    [rangeFrequency, valuesMode.frequencyMode]
  )

  const ticksAmplitudes = useMemo(
    () => generateTicks(rangeAmplitude, valuesMode.amplitudeMode, MAX_COUNT_LINES_AMPLITUDE),
    [rangeAmplitude, valuesMode.amplitudeMode]
  )

  const {
    barOptions,
    onMouseLeave: onMouseLeaveBar,
    onMouseMove: onMouseMoveBar,
    onMouseUp: onMouseUpBar,
    setIsMainAreaDrag: setIsMainAreaDragBar,
    setIsModulateAreaDrag,
    setBarOptions,
    refIndexBar,
    refIndexModulateBar
  } = useExpressBars()

  const {
    onMouseLeave: onMouseLeaveSection,
    onMouseMove: onMouseMoveSection,
    onMouseUp: onMouseUpSection,
    ...otherSectionsChartProps
  } = useExpressSections(chartData)

  const {
    markerOptions,
    onMouseLeave: onMouseLeaveMarker,
    onMouseMove: onMouseMoveMarker,
    onMouseUp: onMouseUpMarker,
    setIsAreaDrag: setIsAreaDragMarker,
    setMarkerOptions,
    refIndexMarker
  } = useExpressMarkers()

  const fMax = Number(selectedMeasurement.programSpecterDescription?.specterLineCount?.split('_')[2])

  const skz = calculateSKZ({
    chartData: chartData,
    startIndex: 0,
    endIndex: chartData.length - 1,
    isDb: isDbAmplitude
  })

  const { am, fm } = calculateAmFm({
    chartData: chartData,
    startIndex: 5,
    endIndex: chartData.length - 1,
    selectedMeasurement,
    isDb: isDbAmplitude
  })

  const expressBars = getChartDataForExpressBars({ ...barOptions, chartData })

  const calculateExpressBarsResults = useCallback(() => {
    const mainBars = getChartDataForExpressBars({
      ...expressBarOptions,
      chartData
    })

    const resultsBars = mainBars?.map((mainBar, mainIndex) => {
      let isEmpty = true

      if (typeof mainBar !== 'number') {
        isEmpty = false
      }

      const leftModulateBars = isModulateVisible
        ? getChartDataForExpressBarsModulates({
            barOptions: expressBarOptions,
            chartData,
            bar: mainBar,
            separator: -1
          })
            .map((modulateBar, modulateIndex) => {
              if (typeof modulateBar !== 'number') {
                isEmpty = false
              }

              return {
                count: (modulateIndex + 1) * -1,
                modulateBarF: typeof modulateBar !== 'number' ? modulateBar?.frequency : '--',
                modulateBarA: typeof modulateBar !== 'number' ? modulateBar?.amplitude : '--',
                percent:
                  typeof mainBar !== 'number' && typeof modulateBar !== 'number'
                    ? (modulateBar?.amplitude / mainBar?.amplitude) * 100
                    : '--'
              }
            })
            .reverse()
        : []

      const rightModulateBars = isModulateVisible
        ? getChartDataForExpressBarsModulates({
            barOptions: expressBarOptions,
            chartData,
            bar: mainBar,
            separator: 1
          }).map((modulateBar, modulateIndex) => {
            if (typeof modulateBar !== 'number') {
              isEmpty = false
            }

            return {
              count: modulateIndex + 1,
              modulateBarF: typeof modulateBar !== 'number' ? modulateBar?.frequency : '--',
              modulateBarA: typeof modulateBar !== 'number' ? modulateBar?.amplitude : '--',
              percent:
                typeof mainBar !== 'number' && typeof modulateBar !== 'number'
                  ? (modulateBar?.amplitude / mainBar?.amplitude) * 100
                  : '--'
            }
          })
        : []

      return {
        isEmpty,
        count: mainIndex + 1,
        percent: 100,
        leftModulateBars,
        mainBarF: typeof mainBar !== 'number' ? mainBar?.frequency : '--',
        mainBarA: typeof mainBar !== 'number' ? mainBar?.amplitude : '--',
        rightModulateBars
      }
    })

    if (!resultsBars) return

    setResultsBar({
      title,
      isDb: isModulateVisible,
      results: resultsBars
    })
  }, [chartData, expressBarOptions, isModulateVisible, setResultsBar, title])

  const addChartHandlers = () => {
    if (isSectionExpressBarOpen) {
      return {
        onMouseLeave: onMouseLeaveSection,
        onMouseUp: onMouseUpSection,
        onMouseMove: onMouseMoveSection
      }
    } else if (isMarkersExpressBarOpen) {
      return {
        onMouseLeave: onMouseLeaveMarker,
        onMouseUp: onMouseUpMarker,
        onMouseMove: onMouseMoveMarker
      }
    } else if (isBarsExpressBarOpen) {
      return {
        onMouseLeave: onMouseLeaveBar,
        onMouseUp: onMouseUpBar,
        onMouseMove: onMouseMoveBar
      }
    }

    return {}
  }

  //подключаю обработчики для горячих кнопок для маркеров
  useMarkersKeyHandler({
    markerOptions,
    chartData,
    refIndexMarker,
    setMarkerOptions,
    resultId
  })

  //эффекты для расчета результатов и для инициализации маркеров
  useMarkersEffects({ isDb: isModulateVisible, chartData, title })

  //подключаю обработчики для горячих кнопок для рядов
  useBarsKeyHandlers({
    barOptions,
    setBarOptions,
    refIndexBar,
    chartData,
    resultId
  })

  const calculateExpressSectionResults = useCallback(() => {
    const dataForExpressSections = calculateExpressSectionsData({
      chartData,
      sections,
      selectedMeasurement,
      isDb: valuesMode.amplitudeMode === EAmplitudeMode.DB
    })

    addExpressSectionResult({
      title,
      amplitudeMode: valuesMode.amplitudeMode,
      unitType: targetUnit,
      data: dataForExpressSections
    })
  }, [addExpressSectionResult, chartData, sections, selectedMeasurement, targetUnit, title, valuesMode.amplitudeMode])

  useEffect(() => {
    if (isBarsExpressBarOpen) {
      calculateExpressBarsResults()
    }
  }, [calculateExpressBarsResults, isBarsExpressBarOpen])

  useEffect(() => {
    if (isSectionExpressBarOpen) {
      calculateExpressSectionResults()
    }
  }, [calculateExpressSectionResults, isSectionExpressBarOpen])

  const handleResetIndexesDoubleClick = () => {
    if (rangeIndexes) {
      setSliderIndexes(rangeIndexes)
    }
  }

  const handleResetAmplitudeDoubleClick = () => {
    if (rangeAmplitude) {
      setSliderAmplitude(rangeAmplitude)
    }
  }

  const handleSliderDisableCheckboxChange = (evt: CheckboxChangeEvent) => {
    const notChecked = !evt.target.checked
    setAmplitudeSliderDisabled(notChecked)
  }

  const stepY = getStepSlider(20, rangeAmplitude)

  const handleAmplitudeModeSelect = (mode: EAmplitudeMode) => {
    setValuesMode({ ...valuesMode, amplitudeMode: mode })
  }

  const handleFrequencyModeSelect = (mode: EFrequencyMode) => {
    setValuesMode({ ...valuesMode, frequencyMode: mode })
  }
  return (
    <ChartWrapper onChartClick={onChartClick} resultId={resultId} chartButtonRef={chartButtonRef} title={title}>
      <Flex>
        <SliderApp
          min={rangeAmplitude ? rangeAmplitude[0] : 0}
          max={rangeAmplitude ? rangeAmplitude[1] : 0}
          value={sliderAmplitude}
          onChange={setSliderAmplitude}
          range={{ draggableTrack: true }}
          step={stepY}
          vertical={true}
          tooltip={{ formatter: chartFormatValue }}
          onDoubleClick={handleResetAmplitudeDoubleClick}
          onCheckboxChange={handleSliderDisableCheckboxChange}
          disabled={amplitudeSliderDisabled}
        />

        <Flex flex={1} vertical={true} gap={5}>
          <ChartsTools>
            <ChartUnits unit={targetUnit} onSetUnitChange={setTargetUnit} />
            <ChartSpectrumPanel
              chartFreqMode={valuesMode.frequencyMode}
              chartAmplitudeMode={valuesMode.amplitudeMode}
              onAmplitudeModeSelect={handleAmplitudeModeSelect}
              onFrequencyModeSelect={handleFrequencyModeSelect}
            />

            {isBarsExpressBarOpen ? (
              <Checkbox
                disabled={barOptions.countOfModulateBars === 0}
                checked={isModulateVisible}
                onChange={() => setIsModulateVisible((prev) => !prev)}
              >
                m
              </Checkbox>
            ) : null}
          </ChartsTools>
          <ResponsiveContainer height={350}>
            <ComposedChart {...addChartHandlers()} data={chartData}>
              <CartesianGrid color={'black'} strokeDasharray='3 3' />
              <XAxis
                dataKey='frequency'
                domain={rangeFrequency}
                type='number'
                axisLine={true}
                ticks={ticksFrequencies}
                tick={{ fill: 'black' }}
                allowDataOverflow={true}
                includeHidden={true}
                scale={isFrequencyModeLog ? 'log' : 'auto'}
              />
              <YAxis
                dataKey='amplitude'
                type='number'
                ticks={ticksAmplitudes}
                tickMargin={5}
                allowDataOverflow={true}
                tickLine={true}
                tick={{ fill: 'black' }}
                domain={sliderAmplitude}
                scale={isAmplitudeModeLog ? 'log' : 'linear'}
              />
              <Line isAnimationActive={false} dot={false} dataKey='amplitude' stroke='#444444' strokeWidth={1} />

              {isBarsExpressBarOpen
                ? renderExpressBarsChart({
                    chartData,
                    expressBars,
                    barOptions,
                    setIsMainAreaDragBar,
                    setIsModulateAreaDrag,
                    refIndexBar,
                    refIndexModulateBar,
                    isModulateVisible
                  })
                : null}

              {isMarkersExpressBarOpen
                ? renderExpressMarkersChart({
                    markerOptions,
                    refIndexMarker,
                    setIsAreaDragMarker,
                    chartData
                  })
                : null}

              {isSectionExpressBarOpen
                ? renderExpressSectionsChart({
                    ...otherSectionsChartProps,
                    chartData,
                    sliderValuesHorizontal: sliderIndexes
                  })
                : null}

              <Legend align='right' verticalAlign='top' content={<LegendSection skz={skz} am={am} fm={fm} />} />

              <Tooltip
                content={<TooltipSpectrum isLog={isAmplitudeModeLog} dfValue={deltaFrequency} fMax={fMax} />}
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <SliderApp
            onDoubleClick={handleResetIndexesDoubleClick}
            min={rangeIndexes[0]}
            max={rangeIndexes[1]}
            value={sliderIndexes}
            onChange={setSliderIndexes}
            range={{ draggableTrack: true }}
          />
        </Flex>
      </Flex>
    </ChartWrapper>
  )
}

export default SpectrumCascade
