import {
  unitBaseAccelerationOptions,
  unitBaseDisplacementOptions,
  unitBaseVelocityOptions,
  unitOptionsAcceleration,
  unitOptionsAmperage,
  unitOptionsByTemperature,
  unitOptionsDRPM,
  unitOptionsRPM,
  unitOptionsVoltage
} from '@/constants/measurement/unit.constant'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { IOptions } from '@/types/common/options.interface'

export const getUnitTypeOptions = (physicalQuantityType?: EPhysicalQuantityType) => {
  if (physicalQuantityType) {
    const unitByPointTypeOptions: {
      [key in EPhysicalQuantityType]?: IOptions<EUnitType>[]
    } = {
      [EPhysicalQuantityType.TEMPERATURE]: unitOptionsByTemperature,
      [EPhysicalQuantityType.VIBRO_ACCELERATION]: unitOptionsAcceleration,
      [EPhysicalQuantityType.VIBRO_VELOCITY]: unitOptionsAcceleration,
      [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: unitOptionsAcceleration,
      [EPhysicalQuantityType.VOLTAGE]: unitOptionsVoltage,
      [EPhysicalQuantityType.AMPERAGE]: unitOptionsAmperage,
      [EPhysicalQuantityType.DRPM]: unitOptionsDRPM,
      [EPhysicalQuantityType.RPM]: unitOptionsRPM,
      [EPhysicalQuantityType.PHASE]: undefined,
      [EPhysicalQuantityType.NULL]: undefined
    }

    return unitByPointTypeOptions[physicalQuantityType]
  }
}

export const getUnitTypeOptionsWithoutAssociation = (physicalQuantityType?: EPhysicalQuantityType) => {
  if (physicalQuantityType) {
    const unitByPointTypeOptions: {
      [key in EPhysicalQuantityType]?: IOptions<EUnitType>[]
    } = {
      [EPhysicalQuantityType.TEMPERATURE]: unitOptionsByTemperature,
      [EPhysicalQuantityType.VIBRO_ACCELERATION]: unitBaseAccelerationOptions,
      [EPhysicalQuantityType.VIBRO_VELOCITY]: unitBaseVelocityOptions,
      [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: unitBaseDisplacementOptions,
      [EPhysicalQuantityType.VOLTAGE]: unitOptionsVoltage,
      [EPhysicalQuantityType.AMPERAGE]: unitOptionsAmperage,
      [EPhysicalQuantityType.DRPM]: unitOptionsDRPM,
      [EPhysicalQuantityType.RPM]: unitOptionsRPM,
      [EPhysicalQuantityType.PHASE]: undefined,
      [EPhysicalQuantityType.NULL]: undefined
    }

    return unitByPointTypeOptions[physicalQuantityType]
  }
}

export const getUnitVibroBaseOptions = () => unitBaseAccelerationOptions
