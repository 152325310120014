export const enum EDeviceContent {
  VP_3701,
  GENERATOR,
  VP_3711,
  VP_3701_DEVICE,
  VP_3711_DEVICE,
  GENERATOR_DEVICE,
  VP_3711_MODULE,
  VP_3711_CHANNEL
}
