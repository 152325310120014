import { ApiTag } from '@/constants/store/api.constants'
import type { rootReducer } from '@/store/reducers/root-reducer'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  rootStore,
  extraOptions
) => {
  const baseUrl = `${
    (rootStore.getState() as ReturnType<typeof rootReducer>).serverSettingsReducer.baseUrl
  }/configurator`

  if (!baseUrl) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No project ID received'
      }
    }
  }

  const rawBaseQuery = fetchBaseQuery({
    baseUrl
  })

  return rawBaseQuery(args, rootStore, extraOptions)
}

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: dynamicBaseQuery,
  refetchOnFocus: true,
  tagTypes: [
    ApiTag.Connections,
    ApiTag.Devices,
    ApiTag.Machines,
    ApiTag.MeasuringPoints,
    ApiTag.Measurements,
    ApiTag.Plans,
    ApiTag.Threshold,
    ApiTag.Mnemo,
    ApiTag.Info,
    ApiTag.Images,
    ApiTag.Kinematic,
    ApiTag.Vp3701,
    ApiTag.ServerName,
    ApiTag.GetAllNodes,
    ApiTag.Ltr
  ],
  endpoints: () => ({})
})
