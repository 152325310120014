import ButtonDropdown from '@/components/UI/buttons/button-dropdown/button-dropdown'
import ButtonLink from '@/components/UI/buttons/button-link/button-link'
import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import PopupDelete from '@/components/UI/elements/popup-delete/popup-delete'
import type { IAttributesKinematicBlocks } from '@/components/kinematic-space/kinematic-space.type'
import { attributesKinematicBlocks } from '@/components/kinematic-space/kinematic.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useAddKinematicElementMutation,
  useGetKinematicElementsQuery,
  useRemoveCurrentKinematicElementMutation
} from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { MenuProps } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'
import { shallowEqual } from 'react-redux'

import styles from './configuration-toolbar.module.css'

type TProps = {
  totalFilteredElements: string
  selectedElement: IKinematicElement | null
  kinematicSchemeId?: string
}

const ConfigurationToolbar: FC<TProps> = ({ totalFilteredElements, selectedElement, kinematicSchemeId }) => {
  const [addKinematicElement] = useAddKinematicElementMutation()
  const [removeCurrentKinematicElement, { isLoading: isLoadingDelete }] = useRemoveCurrentKinematicElementMutation()
  const { refetch: refetchKinematicElements } = useGetKinematicElementsQuery(kinematicSchemeId, {
    skip: !kinematicSchemeId
  })

  const { stageOptions } = useTypedSelector(
    (state) => ({
      ...state.kinematicReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )

  const { setStageOptions, incrementScale, decrementScale, setSelectedKinematic } = useActions()
  const [openedPopup, setOpenedPopup] = useState(false)

  async function handleAddKinematicElement(element: IAttributesKinematicBlocks): Promise<void> {
    try {
      if (!kinematicSchemeId) {
        return
      }

      const nameKinematicElement = `${element.type}${totalFilteredElements}`

      const data = {
        kinematicSchemeId: kinematicSchemeId,
        kinematicBlockName: nameKinematicElement,
        kinematicBlockType: element.type,
        width: element.width,
        height: element.height,
        x: 0,
        y: 0
      }

      const { kinematicBlockName } = await addKinematicElement(data).unwrap()

      successNotificationCreate(`Кинематический блок ${kinematicBlockName} добавлен`)
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  const handleDelete = async () => {
    const { kinematicBlockId, kinematicBlockName } = selectedElement as IKinematicElement
    if (kinematicBlockId && kinematicSchemeId) {
      try {
        await removeCurrentKinematicElement({
          kinematicSchemeId,
          kinematicElementId: kinematicBlockId
        }).unwrap()

        successNotificationCreate(`Кинематический блок ${kinematicBlockName} удалён`)
        setSelectedKinematic(null)
      } catch (e) {
        errorNotificationCreate(e)
      } finally {
        setOpenedPopup(false)
        try {
          refetchKinematicElements()
        } catch (e) {
          errorNotificationCreate(e)
        }
      }
    }
  }

  const handleDeleteButton = () => {
    setOpenedPopup(true)
  }

  const handleClosePopup = () => {
    setOpenedPopup(false)
  }

  const menuMachinesItems: MenuProps['items'] = attributesKinematicBlocks.map((kinematicBlock) => ({
    key: kinematicBlock.type,
    label: (
      <ButtonLink onClick={() => handleAddKinematicElement(kinematicBlock)}>
        <img
          src={kinematicBlock.iconSrc}
          className={styles['kinematic-block-link-image']}
          alt={`Кинематический блок ${kinematicBlock.title}`}
        />
        {kinematicBlock.title}
      </ButtonLink>
    )
  }))

  return (
    <>
      <ButtonDropdown
        className={styles['dropdown-container']}
        menu={{ items: menuMachinesItems }}
        title={'Добавить элемент'}
        icon={'add'}
        placement={'bottomLeft'}
        trigger={['click']}
      />
      <ButtonToolbar
        placement={'top'}
        onClick={() => {
          incrementScale()
        }}
        title={'Увеличить'}
        icon={'zoom_in'}
      />
      <ButtonToolbar
        disabled={stageOptions.scale <= 0.2}
        placement={'top'}
        onClick={() => {
          decrementScale()
        }}
        title={'Уменьшить'}
        icon={'zoom_out'}
      />
      <ButtonToolbar
        placement={'top'}
        onClick={() => {
          setStageOptions({ scale: 1 })
        }}
        title={'Вернуть размер холста'}
      >
        1:1
      </ButtonToolbar>
      <ButtonToolbar
        placement={'top'}
        onClick={handleDeleteButton}
        title={'Удалить'}
        icon={'delete'}
        disabled={!selectedElement}
      />
      <PopupDelete
        title={`Вы действительно хотите удалить кинематический блок ${selectedElement?.kinematicBlockName}?`}
        isOpen={openedPopup}
        onClose={handleClosePopup}
        onDelete={handleDelete}
        isLoading={isLoadingDelete}
      />
    </>
  )
}

export default ConfigurationToolbar
