import CellEditableHarmonic from '@/app/configuration/components/devices/generator/generator-harmonic/cell-editable-harmonic/cell-editable-harmonic'
import type { DataItem } from '@/app/configuration/components/devices/generator/generator.type'
import TableEditable from '@/app/configuration/components/devices/generator/table-editable/table-editable'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import TableWrapper from '@/components/UI/elements/table-wrapper/table-wrapper'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import {
  GeneratorKey,
  StatusValue,
  ampSetModule,
  distrAmplitudes,
  phaseSetModule,
  phases,
  typeModule
} from '@/constants/core/common.constant'
import { GeneratorLabel } from '@/constants/device/deviceOptions'
import { EGeneratorType } from '@/enums/device/generator/generator-type.enum'
import useActions from '@/hooks/use-actions'
import { useUpdateGeneratorMutation } from '@/store/api/devices.api'
import type { IGenerator, THarmonic } from '@/types/devices.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Flex, Form, Table } from 'antd'
import type { FC } from 'react'
import React, { useEffect } from 'react'

import styles from './generator-harmonic.module.css'

type TProps = {
  generatorHarmonic: IGenerator
  isEditGenerator: boolean
  currentGeneratorID: IGenerator['generatorId']
}

const GeneratorHarmonic: FC<TProps> = ({ generatorHarmonic, isEditGenerator, currentGeneratorID }) => {
  const { setIsEditGenerator } = useActions()
  const [updateGenerator] = useUpdateGeneratorMutation()
  const [form] = Form.useForm()

  const harmonicCommonSettings = generatorHarmonic?.harmonicCommonSettings?.harmonics[0] as THarmonic

  // Установка значений полей при редактировании таблицы.
  useEffect(() => {
    if (generatorHarmonic && harmonicCommonSettings) {
      const fieldsValue = {
        [GeneratorKey.Name]: generatorHarmonic.name,
        [GeneratorKey.Fs]: generatorHarmonic.fs || 0,
        [GeneratorKey.TSig]: generatorHarmonic.tsig || 0,
        [GeneratorKey.NormAmp]: generatorHarmonic.harmonicCommonSettings?.normAmp,
        [GeneratorKey.Dclevel]: harmonicCommonSettings.dclevel,
        [GeneratorKey.BaseFreq]: harmonicCommonSettings.baseFreq,
        [GeneratorKey.BaseAmp]: harmonicCommonSettings.baseAmp,
        [GeneratorKey.Nharm]: harmonicCommonSettings.nharm,
        [GeneratorKey.AmpSet]: harmonicCommonSettings.ampSet,
        [GeneratorKey.LastAmp]: harmonicCommonSettings.lastAmp,
        [GeneratorKey.PhaseSet]: harmonicCommonSettings.phaseSet,
        [GeneratorKey.PhaseShift]: harmonicCommonSettings.phaseShift,
        [GeneratorKey.WhiteNoise]: harmonicCommonSettings.whiteNoise,
        [GeneratorKey.M1_Type]: harmonicCommonSettings.m1_Type,
        [GeneratorKey.M1_BaseFreq]: harmonicCommonSettings.m1_BaseFreq,
        [GeneratorKey.M1_DeepT]: harmonicCommonSettings.m1_DeepT,
        [GeneratorKey.M1_DeepA]: harmonicCommonSettings.m1_DeepA,
        [GeneratorKey.M1_Nharm]: harmonicCommonSettings.m1_Nharm,
        [GeneratorKey.M1_AmpSet]: harmonicCommonSettings.m1_AmpSet,
        [GeneratorKey.M1_LastAmp]: harmonicCommonSettings.m1_LastAmp,
        [GeneratorKey.M1_PhaseSet]: harmonicCommonSettings.m1_PhaseSet,
        [GeneratorKey.M1_PhaseShift]: harmonicCommonSettings.m1_PhaseShift,
        [GeneratorKey.M2_Type]: harmonicCommonSettings.m2_Type,
        [GeneratorKey.M2_BaseFreq]: harmonicCommonSettings.m2_BaseFreq,
        [GeneratorKey.M2_DeepT]: harmonicCommonSettings.m2_DeepT,
        [GeneratorKey.M2_DeepA]: harmonicCommonSettings.m2_DeepA,
        [GeneratorKey.M2_Nharm]: harmonicCommonSettings.m2_Nharm,
        [GeneratorKey.M2_AmpSet]: harmonicCommonSettings.m2_AmpSet,
        [GeneratorKey.M2_LastAmp]: harmonicCommonSettings.m2_LastAmp,
        [GeneratorKey.M2_PhaseSet]: harmonicCommonSettings.m2_PhaseSet,
        [GeneratorKey.M2_PhaseShift]: harmonicCommonSettings.m2_PhaseShift
      }
      form.setFieldsValue(fieldsValue)
    }
  }, [form, generatorHarmonic, harmonicCommonSettings])

  if (!harmonicCommonSettings) {
    return null
  }

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'label'
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      onCell: (record: DataItem) => ({
        editing: isEditGenerator,
        record,
        dataIndex: 'label',
        title: 'Наименование'
      })
    }
  ]

  const columnsGeneratorHarmonic = [
    {
      title: 'Параметры генератора',
      children: columns
    }
  ]

  const columnsHarmonicSettings = [
    {
      title: 'Параметры сигнала',
      children: columns
    }
  ]

  const columnsModulatingSettingsOne = [
    {
      title: 'Параметры модулирующего процесса 1',
      children: columns
    }
  ]

  const columnsModulatingSettingsTwo = [
    {
      title: 'Параметры модулирующего процесса 2',
      children: columns
    }
  ]

  const dataGeneratorHarmonic = [
    {
      key: GeneratorKey.Name,
      label: GeneratorLabel.Name,
      value: generatorHarmonic.name || StatusValue.Null
    },
    {
      key: GeneratorKey.Fs,
      label: GeneratorLabel.Fs,
      value: generatorHarmonic.fs || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.TSig,
      label: GeneratorLabel.TSig,
      value: generatorHarmonic.tsig || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.NormAmp,
      label: GeneratorLabel.NormAmp,
      value: generatorHarmonic.harmonicCommonSettings?.normAmp || StatusValue.NullNumber
    }
  ]

  const dataHarmonicSettings = [
    {
      key: GeneratorKey.Dclevel,
      label: GeneratorLabel.Dclevel,
      value: harmonicCommonSettings.dclevel || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.BaseFreq,
      label: GeneratorLabel.BaseFreq,
      value: harmonicCommonSettings.baseFreq || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.BaseAmp,
      label: GeneratorLabel.BaseAmp,
      value: harmonicCommonSettings.baseAmp || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.Nharm,
      label: GeneratorLabel.Nharm,
      value: harmonicCommonSettings.nharm || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.AmpSet,
      label: GeneratorLabel.AmpSet,
      value: distrAmplitudes[harmonicCommonSettings.ampSet]?.label || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.LastAmp,
      label: GeneratorLabel.LastAmp,
      value: harmonicCommonSettings.lastAmp || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.PhaseSet,
      label: GeneratorLabel.PhaseSet,
      value: phases[harmonicCommonSettings.phaseSet]?.label || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.PhaseShift,
      label: GeneratorLabel.PhaseShift,
      value: harmonicCommonSettings.phaseShift || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.WhiteNoise,
      label: GeneratorLabel.WhiteNoise,
      value: harmonicCommonSettings.whiteNoise || StatusValue.NullNumber
    }
  ]

  const dataModulatingSettingsOne = [
    {
      key: GeneratorKey.M1_Type,
      label: GeneratorLabel.M1_Type,
      value: typeModule[Number(harmonicCommonSettings.m1_Type)]?.label || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_BaseFreq,
      label: GeneratorLabel.M1_BaseFreq,
      value: harmonicCommonSettings.m1_BaseFreq || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_DeepT,
      label: GeneratorLabel.M1_DeepT,
      value: harmonicCommonSettings.m1_DeepT || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_DeepA,
      label: GeneratorLabel.M1_DeepA,
      value: harmonicCommonSettings.m1_DeepA || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_Nharm,
      label: GeneratorLabel.M1_Nharm,
      value: harmonicCommonSettings.m1_Nharm || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_AmpSet,
      label: GeneratorLabel.M1_AmpSet,
      value: ampSetModule[Number(harmonicCommonSettings.m1_AmpSet)]?.label || StatusValue.Null
    },
    {
      key: GeneratorKey.M1_LastAmp,
      label: GeneratorLabel.M1_LastAmp,
      value: harmonicCommonSettings.m1_LastAmp || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_PhaseSet,
      label: GeneratorLabel.M1_PhaseSet,
      value: phaseSetModule[Number(harmonicCommonSettings.m1_PhaseSet)]?.label || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M1_PhaseShift,
      label: GeneratorLabel.M1_PhaseShift,
      value: Number(harmonicCommonSettings.m1_PhaseShift) || StatusValue.NullNumber
    }
  ]

  const dataModulatingSettingsTwo = [
    {
      key: GeneratorKey.M2_Type,
      label: GeneratorLabel.M2_Type,
      value: typeModule[Number(harmonicCommonSettings.m2_Type)]?.label || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_BaseFreq,
      label: GeneratorLabel.M2_BaseFreq,
      value: harmonicCommonSettings.m2_BaseFreq || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_DeepT,
      label: GeneratorLabel.M2_DeepT,
      value: harmonicCommonSettings.m2_DeepT || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_DeepA,
      label: GeneratorLabel.M2_DeepA,
      value: harmonicCommonSettings.m2_DeepA || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_Nharm,
      label: GeneratorLabel.M2_Nharm,
      value: harmonicCommonSettings.m2_Nharm || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_AmpSet,
      label: GeneratorLabel.M2_AmpSet,
      value: ampSetModule[Number(harmonicCommonSettings.m2_AmpSet)]?.label || StatusValue.Null
    },
    {
      key: GeneratorKey.M2_LastAmp,
      label: GeneratorLabel.M2_LastAmp,
      value: harmonicCommonSettings.m2_LastAmp || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_PhaseSet,
      label: GeneratorLabel.M2_PhaseSet,
      value: phaseSetModule[Number(harmonicCommonSettings.m2_PhaseSet)]?.label || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.M2_PhaseShift,
      label: GeneratorLabel.M2_PhaseShift,
      value: harmonicCommonSettings.m2_PhaseShift || StatusValue.NullNumber
    }
  ]

  const handleFinish = async () => {
    const fieldsValue = form.getFieldsValue()
    const updatedGenerator = {
      [GeneratorKey.Name]: fieldsValue.name,
      [GeneratorKey.GeneratorId]: currentGeneratorID,
      [GeneratorKey.Fs]: fieldsValue.fs,
      [GeneratorKey.TSig]: fieldsValue.tsig,
      [GeneratorKey.Type]: EGeneratorType.HARMONIC,
      harmonicCommonSettings: {
        harmonics: [
          {
            [GeneratorKey.Dclevel]: fieldsValue.dclevel,
            [GeneratorKey.BaseFreq]: fieldsValue.baseFreq,
            [GeneratorKey.BaseAmp]: fieldsValue.baseAmp,
            [GeneratorKey.Nharm]: fieldsValue.nharm,
            [GeneratorKey.AmpSet]: fieldsValue.ampSet,
            [GeneratorKey.LastAmp]: fieldsValue.lastAmp,
            [GeneratorKey.PhaseSet]: fieldsValue.phaseSet,
            [GeneratorKey.PhaseShift]: fieldsValue.phaseShift,
            [GeneratorKey.WhiteNoise]: fieldsValue.whiteNoise,
            [GeneratorKey.M1_Type]: fieldsValue.m1_Type,
            [GeneratorKey.M1_BaseFreq]: fieldsValue.m1_BaseFreq,
            [GeneratorKey.M1_DeepT]: fieldsValue.m1_DeepT,
            [GeneratorKey.M1_DeepA]: fieldsValue.m1_DeepA,
            [GeneratorKey.M1_Nharm]: fieldsValue.m1_Nharm,
            [GeneratorKey.M1_AmpSet]: fieldsValue.m1_AmpSet,
            [GeneratorKey.M1_LastAmp]: fieldsValue.m1_LastAmp,
            [GeneratorKey.M1_PhaseSet]: fieldsValue.m1_PhaseSet,
            [GeneratorKey.M1_PhaseShift]: fieldsValue.m1_PhaseShift,
            [GeneratorKey.M2_Type]: fieldsValue.m2_Type,
            [GeneratorKey.M2_BaseFreq]: fieldsValue.m2_BaseFreq,
            [GeneratorKey.M2_DeepT]: fieldsValue.m2_DeepT,
            [GeneratorKey.M2_DeepA]: fieldsValue.m2_DeepA,
            [GeneratorKey.M2_Nharm]: fieldsValue.m2_Nharm,
            [GeneratorKey.M2_AmpSet]: fieldsValue.m2_AmpSet,
            [GeneratorKey.M2_LastAmp]: fieldsValue.m2_LastAmp,
            [GeneratorKey.M2_PhaseSet]: fieldsValue.m2_PhaseSet,
            [GeneratorKey.M2_PhaseShift]: fieldsValue.m2_PhaseShift
          }
        ],
        [GeneratorKey.NormAmp]: fieldsValue.normAmp
      }
    } as IGenerator

    try {
      await updateGenerator(updatedGenerator).unwrap()
      setIsEditGenerator(!isEditGenerator)
      successNotificationCreate('Генератор обновлён')
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  const handleEditGenerator = () => {
    setIsEditGenerator(!isEditGenerator)
  }

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex>
            <span className='material-symbols-outlined'>error_med</span>
            <span>Гармонический генератор {generatorHarmonic.name}</span>
          </Flex>
        }
      />
      <ContentWrapper>
        <TableEditable
          form={form}
          onFinish={handleFinish}
          onReset={handleEditGenerator}
          isEdit={isEditGenerator}
          className={styles.form}
        >
          <TableWrapper className={styles['table-container']}>
            <Table
              components={{
                body: {
                  cell: CellEditableHarmonic
                }
              }}
              bordered={true}
              dataSource={dataGeneratorHarmonic}
              columns={columnsGeneratorHarmonic}
              pagination={false}
            />
          </TableWrapper>
          <TableWrapper>
            <Table
              components={{
                body: {
                  cell: CellEditableHarmonic
                }
              }}
              bordered={true}
              dataSource={dataHarmonicSettings}
              columns={columnsHarmonicSettings}
              pagination={false}
            />
          </TableWrapper>
          <TableWrapper>
            <Table
              components={{
                body: {
                  cell: CellEditableHarmonic
                }
              }}
              bordered={true}
              dataSource={dataModulatingSettingsOne}
              columns={columnsModulatingSettingsOne}
              pagination={false}
            />
          </TableWrapper>
          <TableWrapper>
            <Table
              components={{
                body: {
                  cell: CellEditableHarmonic
                }
              }}
              bordered={true}
              dataSource={dataModulatingSettingsTwo}
              columns={columnsModulatingSettingsTwo}
              pagination={false}
            />
          </TableWrapper>
        </TableEditable>
      </ContentWrapper>
    </>
  )
}
export default GeneratorHarmonic
