import useEscHandler from '@/hooks/use-esc-handler'
import cn from 'classnames'
import type { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/elements/popup/popup.module.css'

type IProps = {
  isOpen: boolean
  title: string
  heightS?: boolean
  onCancel?: () => void
  isError?: boolean
  className?: string
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Popup: FC<PropsWithChildren<IProps>> = ({ isOpen, title, children, onCancel, isError, className, ...props }) => {
  useEscHandler(onCancel)

  return (
    <div
      {...props}
      className={cn(styles.overlay, className, {
        [styles['overlay-hidden']]: !isOpen
      })}
    >
      <div
        className={cn(styles.container, {
          [styles['shake-container']]: isError
        })}
      >
        <p className={styles.title}>{title}</p>
        <div className={styles.content}>{children}</div>
        {onCancel ? <button type='reset' className={styles['button-close']} onClick={onCancel} /> : null}
      </div>
    </div>
  )
}

export default Popup
