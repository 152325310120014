import SpectrumCascade from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-cascade/spectrum-cascade'
import SpectrumOverlay from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/spectrum-overlay'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { EChartsViewMode } from '@/enums/charts/chart-view-mode.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TMeasurementResult } from '@/store/api/measurements.api'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import { formatDate } from '@/utils/format-date'
import type { FC, LegacyRef, MutableRefObject } from 'react'
import React, { Fragment } from 'react'

type TChartSpectrumProps = {
  measurementResults: TMeasurementResult[]
  selectedMeasurement?: IMeasurement
  chartStrobesRefs: MutableRefObject<LegacyRef<HTMLButtonElement>[] | null>
  onChartClick: (index: number, resultId: string) => void
}

const ChartSpectrum: FC<TChartSpectrumProps> = ({
  measurementResults,
  selectedMeasurement,
  chartStrobesRefs,
  onChartClick
}) => {
  const { chartsViewMode } = useTypedSelector((state) => state.chartsUiReducer)
  const isViewModeCascade = chartsViewMode === EChartsViewMode.Cascade
  const isViewModeOverlay = chartsViewMode === EChartsViewMode.Overlay
  if (isViewModeCascade && selectedMeasurement) {
    return measurementResults?.map((result, index) => {
      const data = result.powerSpecterValue?.values || []
      const title = `${index + 1}. ${selectedMeasurement.name} ${formatDate(result.timestamp, DATE_TEMPLATE)}`
      return (
        <Fragment key={result.resultId}>
          <SpectrumCascade
            resultId={result.resultId}
            onChartClick={() => onChartClick(index, result.resultId)}
            title={title}
            chartButtonRef={chartStrobesRefs.current && chartStrobesRefs.current[index]}
            sourceData={data}
            selectedMeasurement={selectedMeasurement}
          />
        </Fragment>
      )
    })
  }

  if (isViewModeOverlay && selectedMeasurement) {
    return (
      <SpectrumOverlay
        measurementResults={measurementResults}
        title={selectedMeasurement.name}
        resultId={measurementResults[0].resultId}
        selectedMeasurement={selectedMeasurement}
      />
    )
  }
}

export default ChartSpectrum
