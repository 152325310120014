import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { Key } from 'react'

// Интерфейс IFoundNode с обобщением
interface IFoundNode<T> {
  node: ITreeNode<T>
  path: Key[]
}

// Основная функция поиска узла
export const findNode = <T>(treeData: ITreeNode<T>[], key: Key, path: Key[] = []): IFoundNode<T> | undefined => {
  for (const node of treeData) {
    const currentPath = [...path, node.key]

    // Если найден нужный узел, возвращаем его и путь
    if (node.key === key) {
      return { node, path: currentPath }
    }

    // Если у узла есть дети, продолжаем поиск рекурсивно
    if (node.children) {
      const childNode = findNode<T>(node.children, key, currentPath)
      if (childNode) {
        return childNode
      }
    }
  }

  // Если узел не найден, возвращаем undefined
  return undefined
}
