import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import CommonTitle from '@/components/UI/panel/common-title/common-title'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import { ButtonTitle } from '@/constants/core/common.constant'
import { scaleTypeOptions } from '@/constants/device/vp3711/scale-type.constant'
import { pointTypes } from '@/constants/point/point.constant'
import { ELtre24RangeType } from '@/enums/ltr/ltre24-range-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import { useGetLtrModuleQuery, useUpdateChannelInModuleMutation } from '@/store/api/ltr.api'
import type { TVp3711ChannelForm } from '@/types/device/vp3711/vp3711-channel-form'
import type { TVp3711ModuleForm } from '@/types/device/vp3711/vp3711-module-form.type'
import type { ILtr24ChannelUpdate } from '@/types/ltr/channel/ltr24-channel-update.interface'
import type { ILtrModule } from '@/types/ltr/ltr-module/ltr-module.interface'
import { generateChannelName } from '@/utils/device/generate-channel-name'
import { getUnitTypeOptionsWithoutAssociation } from '@/utils/measurement/get-unit-type-options'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Checkbox, Flex, Form, Input, InputNumber, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'

import styles from './vp3711-channel.module.css'

type TProps = {
  deviceId: string | undefined
}

const getValuesForForm = (ltrModule: ILtrModule): TVp3711ModuleForm => ({
  name: ltrModule.name,
  comment: ltrModule.comment,
  testMode: ltrModule.testMode
})

const availablePhysicalOptions = [
  EPhysicalQuantityType.VOLTAGE,
  EPhysicalQuantityType.VIBRO_ACCELERATION,
  EPhysicalQuantityType.VIBRO_DISPLACEMENT,
  EPhysicalQuantityType.VIBRO_VELOCITY,
  EPhysicalQuantityType.AMPERAGE
]

export const availableScaleTypeByEnableIcp = [ELtre24RangeType.LTR24_ICP_RANGE_1, ELtre24RangeType.LTR24_ICP_RANGE_5]

const physicalOptions = pointTypes
  .filter((item) => availablePhysicalOptions.includes(item.value))
  .map((item) => ({ ...item, disabled: false }))

const Vp3711ChannelPanel: FC<TProps> = ({ deviceId }) => {
  const partsDeviceId = deviceId?.split('-')
  const [moduleId, channelIndex] = partsDeviceId ? partsDeviceId : []
  const { data: ltrModule, isLoading } = useGetLtrModuleQuery({ ltrModuleId: moduleId }, { skip: !moduleId })
  const [triggerUpdateChannel, { isLoading: isUpdating }] = useUpdateChannelInModuleMutation()

  const [form] = useForm<TVp3711ChannelForm>()
  const channel = ltrModule?.ltr24ChannelSettings.find((_, index) => index === Number(channelIndex))
  const name = generateChannelName(Number(channelIndex))

  const physicalQuantityTypeWatched = useWatch('physicalQuantityType', form)
  const icpModeWatched = useWatch('icpMode', form)

  const filteredScaleTypeOptions = useMemo(
    () =>
      icpModeWatched
        ? [
            scaleTypeOptions[0],
            ...scaleTypeOptions.filter((item) => availableScaleTypeByEnableIcp.includes(item.value))
          ]
        : scaleTypeOptions.filter((item) => !availableScaleTypeByEnableIcp.includes(item.value)),
    [icpModeWatched]
  )

  useEffect(() => {
    if (channel) {
      const data: TVp3711ChannelForm = {
        ...channel,
        name: channel.name || name
      }
      form.setFieldsValue(data)
    }
  }, [channel, form, name])

  if (isLoading) {
    return <Spinner />
  }

  if (!channel) {
    return undefined
  }

  const handleUpdateVp3711ModuleFinish = async () => {
    const fields = form.getFieldsValue()

    try {
      for (const key in fields) {
        if (key !== 'sensorROut' && fields[key as keyof ILtr24ChannelUpdate] === undefined) {
          throw new Error(`Поле ${key} не должно быть undefined`)
        }
      }
      const checkedFields = fields as ILtr24ChannelUpdate

      await triggerUpdateChannel({ ltrModuleId: moduleId, channelNumber: channelIndex, body: checkedFields }).unwrap()
      successNotificationCreate('Параметры канала обновлены')
    } catch (e) {
      console.error(e)
      errorNotificationCreate(e)
    }
  }

  const handleCancelForm = async () => {
    form.setFieldsValue(channel)
  }

  const unitOptions = getUnitTypeOptionsWithoutAssociation(physicalQuantityTypeWatched)

  // enabled: доступен //
  // scaleType: значение шкалы, по дефолту ренже LTR24_RANGE_10, в зависимости от типа входа
  // acMode: режим переменного тока // true
  // icpMode: режим ICP // false
  // adcOver: перезгрузка АЦП
  // gainCoefficient: коэффициент усиления // 1
  // name: имя
  // physicalQuantityType: тип физической величины // виброускоренеие // кроме температуры и частоты вращения.
  // unitType: тип единицы измерения // m/s^2 - если виброускорение
  // sensorROut: выходное сопротивление датчика
  // channelNumber: номер канала

  const handlePhysicalQuantityChange = (value: EPhysicalQuantityType) => {
    const baseValue = getUnitTypeOptionsWithoutAssociation(value)
    if (baseValue) {
      form.setFieldValue('unitType', baseValue[0])
    }
  }

  const handleResetScaleTypeChange = () => {
    form.setFieldValue('scaleType', undefined)
  }

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex gap={5} align='center'>
            <span className='material-symbols-outlined'>login</span>
            <span>Канал {channel.name || name}</span>
          </Flex>
        }
      />
      <ContentWrapper>
        <Form className={styles.container} form={form} onFinish={handleUpdateVp3711ModuleFinish} disabled={isUpdating}>
          <CommonTitle title='Параметры канала' />
          <ControlsWrapper>
            <FormItemApp
              name='name'
              label='Наименование канала'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Input />
            </FormItemApp>
            <FormItemApp name='enabled' label='Доступен' initialValue={true} valuePropName='checked'>
              <Checkbox />
            </FormItemApp>
            <FormItemApp
              name='physicalQuantityType'
              label='Тип физических величин'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Select
                options={physicalOptions}
                placeholder='Выберите тип физической величины'
                onChange={handlePhysicalQuantityChange}
              />
            </FormItemApp>
            <FormItemApp
              name='unitType'
              label='Единица измерения'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Select
                options={unitOptions}
                placeholder={
                  physicalQuantityTypeWatched ? 'Выберите единицу измерения' : 'Выберите тип физической величины'
                }
                disabled={!physicalQuantityTypeWatched}
              />
            </FormItemApp>
            <FormItemApp
              name='gainCoefficient'
              label='Коэффициент усиления'
              initialValue={1}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <InputNumber placeholder={'Введите коэффициент усиления'} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <FormItemApp name='icpMode' label='Режим ICP' valuePropName='checked' initialValue={true}>
              <Checkbox onChange={handleResetScaleTypeChange} />
            </FormItemApp>
            <FormItemApp
              name='scaleType'
              label='Значения шкалы'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Select options={filteredScaleTypeOptions} placeholder='Выберите значение шкалы' />
            </FormItemApp>
            <FormItemApp name='acMode' label='Режим переменного тока' initialValue={true} valuePropName='checked'>
              <Checkbox />
            </FormItemApp>
            <FormItemApp name='sensorROut' label='Выходное сопротивление датчика' initialValue={1}>
              <InputNumber disabled={true} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                title={ButtonTitle.Reset}
                isLoadingSkeleton={false}
                isLoading={false}
                loading={isUpdating}
                onClick={handleCancelForm}
              />
              <ButtonPrimary htmlType='submit' title={ButtonTitle.Save} loading={isUpdating} />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}

export default Vp3711ChannelPanel
