import NavigationSettings from '@/app/settings/components/navigation-settings/navigation-settings'
import PopupServerSettings from '@/app/settings/components/server-settings/components/popup-server-settings'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import FormWrapper from '@/components/UI/elements/form-wrapper/form-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import FormInformation from '@/components/UI/form-controls/form-information/form-information'
import FormSelect from '@/components/UI/form-controls/form-select/form-select'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import PanelTitle from '@/components/UI/panel/panel-title/panel-title'
import { BASE_URL_CLOUD } from '@/config/connection.config'
import { ButtonTitle } from '@/constants/core/common.constant'
import { SuccessMessage } from '@/constants/notifications.constant'
import { ServerLabel, ServerName } from '@/constants/settings/settings-form.constant'
import { KEY_BASE_URL, ServerType, SettingsTitle, serverTypeMap } from '@/constants/settings/settings.constant'
import useActions from '@/hooks/use-actions'
import { useAppDispatch } from '@/hooks/use-app-dispatch'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { baseApi } from '@/store/api/api-base-query'
import { useLazyGetInfoQuery } from '@/store/api/info.api'
import { useGetServerNameQuery, useUpdateServerNameMutation } from '@/store/api/server-name.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { RadioChangeEvent } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React, { useState } from 'react'

import styles from './server-settings.module.css'

export const ServerSettings: FC = () => {
  const dispatch = useAppDispatch()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [serverType, setServerType] = useState<ServerType>(ServerType.Cloud)
  const [serverForm] = useForm()
  const { setServer, resetInitial } = useActions()
  const { data: serverName, isLoading, refetch: refetchServerName } = useGetServerNameQuery()
  const [updateServerName] = useUpdateServerNameMutation()
  const { isDesktop } = useTypedSelector((state) => state.serverSettingsReducer)
  const [getInfo, { isFetching }] = useLazyGetInfoQuery()
  const addressServer = serverName?.ip ? new URL(serverName.ip, BASE_URL_CLOUD) : null

  const getServerType = () => {
    if (addressServer) {
      return addressServer.origin === BASE_URL_CLOUD ? serverTypeMap[ServerType.Cloud] : serverTypeMap[ServerType.Local]
    }

    return null
  }

  const handleTogglePopup = () => {
    setIsOpenPopup(!isOpenPopup)
  }

  const handleFinish = async () => {
    const fields = serverForm.getFieldsValue()
    let host = ''
    const ssl = fields[ServerName.Ssl] ? 'https' : 'http'

    if (serverType === ServerType.Cloud) {
      host = BASE_URL_CLOUD
    }

    if (serverType === ServerType.Local) {
      host = `${ssl}://${fields[ServerName.ServerAddress]}:${fields[ServerName.Port]}`
    }

    setServer(host)
    try {
      await getInfo().unwrap()
      localStorage.setItem(KEY_BASE_URL, host)
      updateServerName({ ip: host, name: fields[ServerName.ServerName] })
      refetchServerName()
      dispatch(baseApi.util.resetApiState())
      resetInitial()
      successNotificationCreate(SuccessMessage.SuccessfulConnection)
      setIsOpenPopup(false)
      serverForm.resetFields()
    } catch (error) {
      errorNotificationCreate(error, {
        message: 'Ошибка подключения',
        description: `Связь с хостом ${host} отсутствует`
      })
    }
  }

  const handleChangeType = (evt: RadioChangeEvent) => {
    setServerType(evt.target.value)
  }

  return (
    <Container>
      <ToolBar />
      <LeftBar>
        <NavigationSettings />
      </LeftBar>
      <WorkSpace>
        <HeaderConfiguration content={SettingsTitle.Server} />
        {isDesktop ? (
          <>
            <FormWrapper className={styles.container}>
              <ControlsWrapper>
                <PanelTitle>Текущее подключение</PanelTitle>
                <FormInformation
                  label='Тип'
                  value={getServerType() || 'Значение не определено'}
                  isLoading={isLoading}
                />
                {getServerType() === serverTypeMap[ServerType.Local] ? (
                  <FormInformation
                    label={ServerLabel.ServerName}
                    value={serverName?.name || 'Значение неопределено'}
                    isLoading={isLoading}
                  />
                ) : null}
                <FormInformation
                  label={ServerLabel.ServerAddress}
                  value={addressServer ? addressServer.hostname : 'Значение неопределено'}
                  isLoading={isLoading}
                />
              </ControlsWrapper>
              <ControlsWrapper>
                <PanelTitle>Доступные подключения</PanelTitle>
                <FormSelect
                  labelContent='Список серверов'
                  options={undefined}
                  placeholder='Выберите адрес сервера'
                  disabled={true}
                  title='Не реализовано'
                  required={false}
                />
              </ControlsWrapper>
              <ControlsWrapper>
                <ButtonsWrapper>
                  <ButtonPrimary
                    title={ButtonTitle.Exit}
                    htmlType='button'
                    onClick={() => null}
                    isLoadingSkeleton={false}
                  />
                  <ButtonPrimary
                    title='Установить новое соединение'
                    htmlType='button'
                    onClick={handleTogglePopup}
                    isLoadingSkeleton={false}
                  />
                </ButtonsWrapper>
              </ControlsWrapper>
            </FormWrapper>
            <PopupServerSettings
              isOpen={isOpenPopup}
              onClosePopup={handleTogglePopup}
              serverForm={serverForm}
              onFinish={handleFinish}
              isFetching={isFetching}
              onChangeType={handleChangeType}
              serverType={serverType}
            />
          </>
        ) : (
          <p style={{ margin: '20px 0', display: 'block', textAlign: 'center' }}>
            Настройки подключения к серверу доступны только в настольном приложении.
          </p>
        )}
      </WorkSpace>
    </Container>
  )
}

export default ServerSettings
