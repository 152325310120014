import CollapseWrapper from '@/app/reports/components/collapse-wrapper/collapse-wrapper'
import Spinner from '@/components/UI/elements/spinner/spinner'
import SwitchIndicator from '@/components/UI/elements/switch-indicator/switch-indicator'
import TableWrapper from '@/components/UI/elements/table-wrapper/table-wrapper'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { Vp3701Label } from '@/constants/device/deviceOptions'
import { ErrorData } from '@/constants/errors.constant'
import { mapPowerType } from '@/constants/reports.constant'
import useActions from '@/hooks/use-actions'
import type { IVp3701 } from '@/types/device/vp3701/vp3701.interface'
import type { IDeviceVP3701, IGenerator } from '@/types/devices.type'
import type { TValueId } from '@/types/reports/reports.type'
import { formatDate } from '@/utils/format-date'
import { findVp3701Element } from '@/utils/reports'
import { Checkbox, Collapse, Table } from 'antd'
import type { FC, MouseEventHandler } from 'react'

type CollapseVp3701Type = {
  dataVp3701?: IVp3701[]
  isLoading: boolean
  checkedData: IDeviceVP3701[] | IGenerator[]
}

const columnsVp3701 = [
  {
    title: 'Параметр',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Значение',
    dataIndex: 'value',
    key: 'value'
  }
]

const CollapseVp3701: FC<CollapseVp3701Type> = ({ dataVp3701, isLoading, checkedData }) => {
  const checkedVp3701 = checkedData as IDeviceVP3701[]
  const { setCheckedData } = useActions()

  if (isLoading) {
    return <Spinner />
  }

  if (!dataVp3701) {
    return null
  }

  const adaptableData = (dataItem: IDeviceVP3701) => [
    {
      key: '1',
      name: Vp3701Label.Vp3701Id,
      value: dataItem.vp3701Id
    },
    {
      key: '2',
      name: Vp3701Label.Name,
      value: dataItem.name
    },
    {
      key: '3',
      name: Vp3701Label.SerialNumber,
      value: dataItem.serialNumber
    },
    {
      key: '4',
      name: Vp3701Label.IP,
      value: dataItem.ip
    },
    {
      key: '5',
      name: Vp3701Label.PortData,
      value: dataItem.portData
    },
    {
      key: '6',
      name: Vp3701Label.PortSetting,
      value: dataItem.portSetting
    },
    {
      key: '7',
      name: Vp3701Label.PowerType,
      value: mapPowerType[dataItem.powerType]
    },
    {
      key: '8',
      name: Vp3701Label.Version,
      value: dataItem.version
    },
    {
      key: '9',
      name: Vp3701Label.Status,
      value: (
        <SwitchIndicator
          sourceBoolean={dataItem.status === 'ONLINE'}
          trueContent={'Соединение с сервером установлено'}
          falseContent={'Соединение с сервером отсутствует'}
        />
      )
    },
    {
      key: '10',
      name: Vp3701Label.StatusUpdatingTimestamp,
      value: formatDate(dataItem.statusUpdatingTimestamp, DATE_TEMPLATE)
    },
    {
      key: '11',
      name: Vp3701Label.ConnectionStatus,
      value: (
        <SwitchIndicator
          sourceBoolean={dataItem.connectionStatus === 'MEASURING_POINT_CONNECTED'}
          trueContent='Подключение к точке измерения установлено'
          falseContent='Подключение к точке измерения отсутствует'
        />
      )
    }
  ]

  const handleClickCheckbox: MouseEventHandler<HTMLInputElement> = (evt) => {
    evt.stopPropagation()

    const currentElement = findVp3701Element(dataVp3701, evt.currentTarget.value) as IDeviceVP3701

    const isChecked = checkedVp3701.some((value) => value === currentElement)

    if (isChecked) {
      setCheckedData(checkedVp3701.filter((value) => value !== currentElement))
      return
    }

    setCheckedData([...checkedVp3701, currentElement])
  }

  const generationExtra = (idItem: IDeviceVP3701['vp3701Id'], checked: boolean) => (
    <Checkbox value={idItem} onClick={handleClickCheckbox} checked={checked} />
  )

  const isChecked = (valueID: TValueId) => checkedVp3701.some((value) => value.vp3701Id === valueID)

  const items = dataVp3701.map((item) => ({
    key: item.vp3701Id,
    label: item.name || ErrorData.Null,
    children: (
      <TableWrapper>
        <Table pagination={false} columns={columnsVp3701} dataSource={adaptableData(item)} bordered={true} />
      </TableWrapper>
    ),
    extra: generationExtra(item.vp3701Id, isChecked(item.vp3701Id))
  }))

  return (
    <CollapseWrapper data={dataVp3701}>
      <Collapse accordion bordered={false} items={items} collapsible='header' />
    </CollapseWrapper>
  )
}

export default CollapseVp3701
