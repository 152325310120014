import {
  mapGeneratorForTree,
  mapVp3701ForTree,
  mapVp3711ForTree
} from '@/app/configuration/components/devices/devices-tree/device-tree.service'
import { DeviceTypeId, DeviceTypeLabel } from '@/constants/device/device.constant'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetGeneratorsQuery, useGetVp3701ListQuery } from '@/store/api/devices.api'
import { useGetCratesQuery, useGetLtrModulesQuery } from '@/store/api/ltr.api'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import { findNode } from '@/utils/tree/find-node'
import { FolderOutlined } from '@ant-design/icons'
import { Tree } from 'antd'
import type { EventDataNode } from 'antd/es/tree'
import type { FC, Key } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { type ParamKeyValuePair, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import styles from './device-tree.module.css'

const EXPENDED_SEARCH_KEY = 'exp_device'

const DevicesTree: FC = () => {
  const { deviceId } = useParams()
  const location = useLocation()
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const { selectedDevice } = useTypedSelector((state) => state.devicesReducer)
  const { setDeviceContent } = useActions()
  const { data: pagedVp3701List } = useGetVp3701ListQuery(undefined, { pollingInterval: 1000 })
  const { data: pagedGenerators } = useGetGeneratorsQuery()
  const { data: pagedCrates } = useGetCratesQuery()
  const { data: pagedLtrModules } = useGetLtrModulesQuery()
  const vp3701List = pagedVp3701List?.content
  const generators = pagedGenerators?.content
  const crates = pagedCrates?.content
  const ltrModules = pagedLtrModules?.content
  const navigate = useNavigate()

  const deviceTree = useMemo<ITreeNode<EDeviceContent>[]>(
    () => [
      {
        key: DeviceTypeId.Vp3701,
        title: DeviceTypeLabel.Vp3701,
        icon: <FolderOutlined />,
        content: EDeviceContent.VP_3701,
        children: vp3701List ? mapVp3701ForTree(vp3701List) : []
      },
      {
        key: DeviceTypeId.Vp3711,
        title: DeviceTypeLabel.Vp3711,
        icon: <FolderOutlined />,
        content: EDeviceContent.VP_3711,
        children: crates && ltrModules ? mapVp3711ForTree(crates, ltrModules) : []
      },
      {
        key: DeviceTypeId.Generator,
        title: DeviceTypeLabel.Generator,
        icon: <FolderOutlined />,
        content: EDeviceContent.GENERATOR,
        children: generators ? mapGeneratorForTree(generators) : []
      }
    ],
    [crates, generators, ltrModules, vp3701List]
  )

  useEffect(() => {
    if (deviceId) {
      const foundNodePath = findNode<EDeviceContent>(deviceTree, deviceId)
      setSelectedKeys([deviceId])
      setDeviceContent(foundNodePath?.node.content)
    }

    setExpandedKeys(searchParams.getAll(EXPENDED_SEARCH_KEY))
  }, [deviceId, deviceTree, searchParams, setDeviceContent])

  const handleDeviceSelect = (keys: Key[], info: { node: EventDataNode<ITreeNode<EDeviceContent>> }) => {
    if (keys) {
      const { pathname, search } = location
      const idString = keys[0].toString()
      let path = idString

      if (deviceId) {
        const parts = pathname.split('/')

        parts[3] = idString

        path = parts.join('/')
      }

      navigate(`${path}${search}`)
    }
    const { node } = info
    setDeviceContent(node.content)
  }

  const handleExpand = (keys: Key[], info: { expanded: boolean; node: EventDataNode<ITreeNode<EDeviceContent>> }) => {
    const params: ParamKeyValuePair[] = keys.map((item) => [EXPENDED_SEARCH_KEY, item.toString()])
    setSearchParams(params)
  }

  return (
    <Tree
      className={styles['tree']}
      showIcon={true}
      showLine={true}
      treeData={deviceTree}
      onSelect={handleDeviceSelect}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      selectedKeys={selectedKeys}
    />
  )
}

export default DevicesTree
