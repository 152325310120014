import DeleteModal from '@/app/configuration/components/mnemonic-configuration/mnemonic-toolbar/components/delete-modal/delete-modal'
import ImageModal from '@/app/configuration/components/mnemonic-configuration/mnemonic-toolbar/components/image-modal/image-modal'
import { prepareIndicatorData } from '@/app/configuration/components/mnemonic-configuration/mnemonic-toolbar/mnemonic-toolbar.service'
import { ReactComponent as FieldSvg } from '@/assets/images/icons/field.svg'
import { ReactComponent as RoundChartSvg } from '@/assets/images/icons/round-chart.svg'
import { ReactComponent as RoundSvg } from '@/assets/images/icons/round.svg'
import { ReactComponent as VerticalIndicatorSvg } from '@/assets/images/icons/vertical-indicator.svg'
import ButtonSvg from '@/components/UI/buttons/button-svg/button-svg'
import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import HorizontalToolbar from '@/components/UI/elements/horizontal-toolbar/horizontal-toolbar'
import IndicatorSettings from '@/components/mnemonic/indicator-settings/indicator-settings'
import { EMnemoElementType } from '@/enums/mnemo/indicator-type.enum'
import { useGetCurrentMnemoScheme } from '@/hooks/api/use-get-current-mnemo-scheme'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useAddMnemonicItemMutation, useGetMnemonicElementsQuery } from '@/store/api/mnemo.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Tooltip } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

const MnemonicToolbar: FC = () => {
  const { data: currentMnemonicSchemeData } = useGetCurrentMnemoScheme()
  const { selectedMachineId, selectedPointId, selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)
  const { selectedMnemoItemId } = useTypedSelector((state) => state.mnemonicReducer)
  const [imageModalOpened, setImageModalOpened] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [addMnemonicItem] = useAddMnemonicItemMutation()
  const { data: mnemonicElements } = useGetMnemonicElementsQuery(currentMnemonicSchemeData?.mnemoSchemeId, {
    skip: !currentMnemonicSchemeData?.mnemoSchemeId
  })

  const handleAddCircleIndicator = async (mnemoElementType: EMnemoElementType) => {
    try {
      if (currentMnemonicSchemeData?.mnemoSchemeId) {
        const { mnemoSchemeId } = currentMnemonicSchemeData
        const dataCircleIndicator = prepareIndicatorData(
          mnemoElementType,
          mnemoSchemeId,
          mnemonicElements?.totalElements,
          selectedMachineId,
          selectedPointId,
          selectedMeasurementId
        )

        await addMnemonicItem(dataCircleIndicator).unwrap()

        successNotificationCreate('Индикатор успешно добавлен')
      }
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const handleOpenedModalToggle = () => {
    setImageModalOpened(!imageModalOpened)
  }

  const handleOpenedDeleteModalToggle = () => {
    setDeleteModalOpened(!deleteModalOpened)
  }

  return (
    <>
      <HorizontalToolbar>
        <>
          <Tooltip placement={'top'} title='Индикатор состояние'>
            <ButtonSvg
              icon={<RoundSvg />}
              onClick={() => handleAddCircleIndicator(EMnemoElementType.CIRCLE_INDICATOR)}
              data-mnemo-type={EMnemoElementType.CIRCLE_INDICATOR}
            />
          </Tooltip>
          <Tooltip placement={'top'} title='Индикатор состояние: статистический'>
            <ButtonSvg
              icon={<RoundChartSvg />}
              disabled={Boolean(selectedMeasurementId)}
              onClick={() => handleAddCircleIndicator(EMnemoElementType.STATISTICAL_INDICATOR)}
            />
          </Tooltip>
          <Tooltip placement={'top'} title='Индикатор: вертикальный'>
            <ButtonSvg
              icon={<VerticalIndicatorSvg />}
              disabled={!selectedMeasurementId}
              onClick={() => handleAddCircleIndicator(EMnemoElementType.MEASUREMENT_WITH_THRESHOLD_INDICATOR)}
            />
          </Tooltip>
          <Tooltip placement={'top'} title='Индикатор: значение'>
            <ButtonSvg
              icon={<FieldSvg />}
              onClick={() => handleAddCircleIndicator(EMnemoElementType.MEASUREMENT_LAST_VALUE)}
            />
          </Tooltip>
          <ButtonToolbar
            placement='top'
            title='Добавить текст'
            icon='text_fields'
            onClick={() => handleAddCircleIndicator(EMnemoElementType.TEXT_INDICATOR)}
          />
          <ButtonToolbar
            placement='top'
            title='Загрузить изображения'
            icon='imagesmode'
            onClick={handleOpenedModalToggle}
          />
          <ButtonToolbar
            placement='top'
            title='Удалить объект'
            icon='delete'
            onClick={handleOpenedDeleteModalToggle}
            disabled={!selectedMnemoItemId}
          />
          <IndicatorSettings />
        </>
      </HorizontalToolbar>
      <ImageModal open={imageModalOpened} onCancel={handleOpenedModalToggle} />
      <DeleteModal open={deleteModalOpened} onCancel={handleOpenedDeleteModalToggle} />
    </>
  )
}

export default MnemonicToolbar
