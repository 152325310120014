import { useGetCurrentMnemoScheme } from '@/hooks/api/use-get-current-mnemo-scheme'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useRemoveImageMutation } from '@/store/api/images.api'
import { useGetMnemonicElementsQuery, useRemoveMnemonicItemMutation } from '@/store/api/mnemo.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { ModalProps } from 'antd'
import { Modal, Typography } from 'antd'
import type { FC } from 'react'

type TProps = ModalProps & {
  onCancel: () => void
}

const DeleteModal: FC<TProps> = ({ onCancel, ...props }) => {
  const { selectedMnemoItemId } = useTypedSelector((state) => state.mnemonicReducer)
  const [removeElementItem, { isLoading: isRemoving }] = useRemoveMnemonicItemMutation()
  const [removeImage] = useRemoveImageMutation()
  const { data: currentMnemoSchemeData } = useGetCurrentMnemoScheme()
  const { data: mnemonicElements } = useGetMnemonicElementsQuery(currentMnemoSchemeData?.mnemoSchemeId, {
    skip: !currentMnemoSchemeData?.mnemoSchemeId
  })
  const { setSelectedMnemoItemId } = useActions()

  const foundElement = mnemonicElements?.content.find((element) => element.mnemoElementId === selectedMnemoItemId)
  const titleModal = `Вы действительно хотите удалить элемент?`
  const nameElement = foundElement?.name

  const handleRemoveElementOk = async () => {
    try {
      if (foundElement?.mnemoElementId && currentMnemoSchemeData?.mnemoSchemeId) {
        const dataForRemove = {
          mnemoSchemeId: currentMnemoSchemeData.mnemoSchemeId,
          mnemoElementId: foundElement.mnemoElementId
        }
        await removeElementItem(dataForRemove).unwrap()
        successNotificationCreate(`Элемент ${nameElement} успешно удалён`)
        setSelectedMnemoItemId(undefined)
      }
    } catch (err) {
      errorNotificationCreate(err)
    } finally {
      onCancel()
    }
  }

  return (
    <Modal
      centered={true}
      {...props}
      title={titleModal}
      okText={'Удалить'}
      cancelText={'Отмена'}
      maskClosable={false}
      onOk={handleRemoveElementOk}
      loading={isRemoving}
      onCancel={onCancel}
    >
      <Typography.Paragraph>Наименование: {foundElement?.name}</Typography.Paragraph>
    </Modal>
  )
}

export default DeleteModal
