import { ELtre24RangeType } from '@/enums/ltr/ltre24-range-type.enum'
import type { IOptions } from '@/types/common/options.interface'

export const scaleTypeOptions: IOptions<ELtre24RangeType>[] = [
  {
    label: 'Авто',
    value: ELtre24RangeType.AUTO
  },
  {
    label: '2',
    value: ELtre24RangeType.LTR24_RANGE_2
  },
  {
    label: '10',
    value: ELtre24RangeType.LTR24_RANGE_10
  },
  {
    label: '1',
    value: ELtre24RangeType.LTR24_ICP_RANGE_1
  },
  {
    label: '5',
    value: ELtre24RangeType.LTR24_ICP_RANGE_5
  }
]

// ЕСЛИ ICP включен, то два последних.
