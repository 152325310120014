/* eslint-disable no-var */
import { BASE_URL_CLOUD } from '@/config/connection.config'
import { KEY_BASE_URL } from '@/constants/settings/settings.constant'
import { SliceName } from '@/constants/store/slices.constant'
import type { TSettings } from '@/types/settings.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

declare global {
  var IN_DESKTOP_ENV: boolean
  var VERSION: string
}

const initialState: TSettings = {
  baseUrl: globalThis.IN_DESKTOP_ENV ? localStorage.getItem(KEY_BASE_URL) || BASE_URL_CLOUD : '',
  version: globalThis.VERSION || process.env?.REACT_APP_VERSION || '',
  isDesktop: globalThis.IN_DESKTOP_ENV
}

export const serverSettingsSlice = createSlice({
  name: SliceName.ServerSettings,
  initialState,
  reducers: {
    setServer(state, action: PayloadAction<string>) {
      state.baseUrl = action.payload
    }
  }
})
