import { SliceName } from '@/constants/store/slices.constant'
import { EKinematicViewMode } from '@/enums/kinematic/kinematic-view-mode.enum'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import type { IKinematicUiState } from '@/store/reducers/kinematic/kinematic-ui/kinematic-ui.interface'
import type { IKinematicElement } from '@/types/kinematic.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IKinematicUiState = {
  viewMode: EKinematicViewMode.Status,
  workMode: EKinematicWorkMode.None,
  selectedKinematic: null
}

export const kinematicUiSlice = createSlice({
  name: SliceName.KinematicUi,
  initialState,
  reducers: {
    setViewMode(state, action: PayloadAction<EKinematicViewMode>) {
      state.viewMode = action.payload
    },
    setSelectedKinematic(state, action: PayloadAction<IKinematicElement | null>) {
      state.selectedKinematic = action.payload
    },
    setWorkMode(state, action: PayloadAction<EKinematicWorkMode>) {
      state.workMode = action.payload
    }
  }
})
